import { isPlatformBrowser } from '@angular/common';
import { 
  Component, 
  HostListener, 
  Inject, 
  OnInit, 
  PLATFORM_ID
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api/api.service';
import { BranchLocationService } from 'src/app/services/branch-location/branch-location.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { GlobalService } from 'src/app/services/global.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StoreService } from 'src/app/services/store/store.service';
import { ToastService } from 'src/app/services/toast/toast.service';
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
@Component({
  selector: 'app-account-addresses',
  templateUrl: './account-addresses.component.html',
  styleUrls: ['./account-addresses.component.scss']
})
export class AccountAddressesComponent implements OnInit {
  
  modalCtrl: any;
  addressForm: FormGroup;
  newAddress: boolean;
  addressDetails: any
  deliveryType = 'delivery';

  branchLocation = {
    delivery_state: null,
    delivery_city: null,
    delivery_barangay: null,
    branch: null,
    selected_branch: null
  };
  branches: any;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeModal();
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private branchLocationService: BranchLocationService,
    private loaderService: LoaderService,
    private storeService: StoreService,
    private toastService: ToastService,
    public cartService: CartService,
    @Inject(PLATFORM_ID) private platformId
  ) { 
    this.addressForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_first_name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_last_name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_email: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      recipient_mobile: ['', Validators.compose([Validators.maxLength(13), Validators.minLength(11), Validators.pattern(GlobalService.REGEX_PATTERNS.phoneNumber)])],
      recipient_landline: ['',Validators.compose([Validators.maxLength(10), Validators.minLength(9), Validators.pattern(GlobalService.REGEX_PATTERNS.telephoneNumber)])],
      delivery_address: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],      
      delivery_city: ['', Validators.required],
      delivery_barangay: ['', Validators.required],
      delivery_company: ['', Validators.maxLength(50)],
      delivery_state: ['', Validators.required],
      selected_branch: [''],
      branch: ['']
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.provincesInit()
      this.getBranches()
    }
    this.loaderService.hide()
  }

  closeModal() {    
    console.log("Click");
    //this.modalCtrl.close();
    this.modalService.dismissAll()
    
  }

  async provincesInit() {
    const provinces = await this.branchLocationService.getProvinces();
    this.addressForm.get('delivery_state').setValue(provinces);
    this.branchLocation.delivery_state = provinces;
    console.log(provinces);
    console.log(this.branchLocation);
    
  }

  async getBranches() {
    try {
      const branchList = await this.apiService.request({
        method: 'GET',
        url: '/branches',
        jsona: true
      });
      const sortedBranch = branchList.sort((a, b) => a.title.localeCompare(b.title));
      this.branches = sortedBranch;
      console.log('branches => ', this.branches);
    } catch (err) {
      console.log(err);
    }
  }

  async setBranch(branchObj, type) {
    let keys = [];
    switch (type) {
      case 'delivery_state':
        keys = ['delivery_city', 'delivery_barangay', 'branch', 'selected_branch'];
        break;
      case 'delivery_city':
        keys = ['delivery_barangay', 'branch', 'selected_branch'];
        this.branchLocation.delivery_city = branchObj.cities;
        break;
      case 'delivery_barangay':
        keys = ['branch', 'selected_branch'];
        this.branchLocation.delivery_barangay = branchObj.barangays;
        console.log(branchObj.barangays);
        break;
      case 'branch':
        keys = ['selected_branch'];
        this.branchLocation.selected_branch = branchObj.branches;
        break;
      default:
        break;
    }
    keys.map(key => {
      this.branchLocation[key] = null;
      this.addressForm.get(key).setValue(null);
      this.addressForm.get(key).updateValueAndValidity();
    });

    this.addressForm.get(type).setValue(branchObj);
    this.addressForm.get(type).updateValueAndValidity();

    if (branchObj.type === 'provinces') {
      let provinces = await this.apiService.request({
        method: 'GET',
        url: `/provinces/${branchObj.id}?include=cities`,
        jsona: true
      });
      // this.provinces = provinces;
      console.log(provinces)
      console.log(this.addressForm.value)
      this.addressForm.get(type).setValue(provinces);
      this.addressForm.get(type).updateValueAndValidity();
      // await this.updateBranchLocationStore(provinces);
      console.log(this.addressForm.value)
    } else if (branchObj.type === 'cities') {
      let city = await this.apiService.request({
        method: 'GET',
        url: `/cities/${branchObj.id}?include=barangays`,
        jsona: true
      });
      console.log(city)
      console.log(this.addressForm.value)
      this.addressForm.get(type).setValue(city);
      this.addressForm.get(type).updateValueAndValidity();
      console.log(this.addressForm.value)
    } else if (branchObj.type === 'barangays') {
      let barangay = await this.apiService.request({
        method: 'GET',
        url: `/barangays/${branchObj.id}?include=branches`,
        jsona: true
      });
      branchObj = barangay
      console.log(barangay)
      console.log(this.addressForm.value)
      this.addressForm.get(type).setValue(barangay);
      this.addressForm.get(type).updateValueAndValidity();
      console.log(this.addressForm.value)
    } else {
      this.addressForm.get(type).setValue(branchObj);
      this.addressForm.get(type).updateValueAndValidity();
    }

    if (type === 'branch') { // temporary fix only
      const firstBranch = branchObj.branches[0];
      console.log(firstBranch);      
      this.addressForm.get('selected_branch').setValue(firstBranch);
      this.addressForm.get('selected_branch').updateValueAndValidity();
    }
    console.log(this.addressForm.value);
  }

  async register() {
    if (this.addressForm.valid) {
      try {
        let landline = this.addressForm.get('recipient_landline').value;
        if (landline) {
          let firstPart = landline.substring(0, 2);
          let secondPart = landline.substring(2, 6);
          let thirdPart = landline.substring(6, 10);
          landline = `${firstPart} ${secondPart} ${thirdPart}`
        }
        console.log('landline =>', landline);

        if (this.deliveryType === 'delivery') {
          await this.storeService.setItem('brgy_segment', this.addressForm.value.branch.segment);
        }

        const selectedBranch = this.addressForm.get('selected_branch').value;
        const user = await this.storeService.getItem('user');
        await this.storeService.setItem('branchLocation', {
          branch: this.addressForm.value.branch,
          brgy: this.addressForm.value.delivery_barangay,
          city: this.addressForm.value.delivery_city,
          province: this.branches,
          selected_branch: this.addressForm.value.selected_branch,
          isUser: false
        })
        await this.storeService.setItem('deliveryType', this.deliveryType);
        
        if (Array.isArray(this.cartService.cartStorage)) {
          if (this.cartService.cartStorage.length) {
            if (user) {
              await this.cartService.removeAllCartItems();
            } else {
              await this.cartService.emptyCart();
            }
          }
        }

        await this.storeService.setItem('branch', selectedBranch.segment);
        await this.storeService.removeItem('checkout_details');

        const addressPayload = {
          ...this.addressForm.value,
          delivery_barangay: this.addressForm.value.branch.id,
          fill_type: 'manual',          
          recipient_landline: landline
        }

        const savedUserAddress = {
          province: this.addressForm.value.delivery_city,
          city: this.addressForm.value.delivery_barangay,
          barangay: this.addressForm.value.branch,
          address_1: this.addressForm.value.delivery_address,
          company: this.addressForm.value.delivery_company,
        }
        await this.storeService.setItem("savedAddress", savedUserAddress)

        console.log(addressPayload);
        delete addressPayload.selected_branch;
        delete addressPayload.branch;

        const token = await this.storeService.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        this.loaderService.showAsyncLoader();
        const res = await this.apiService.postRequest({
          url: '/v2/auth/user/saved-addresses',
          body: addressPayload,
          headers
        });
        
        let savedAddress = this.apiService.jsonaFormatter(res)
        this.addressDetails = savedAddress
        
        user['profile'].address = savedAddress
        
        const resSet = await this.apiService.putRequest({
          url: `/v2/auth/user/saved-addresses/set-default/${this.addressDetails.id}`,
          body: savedAddress,
          headers,
        })

        await this.storeService.setItem("user", user) 

        

        console.log(res);
        
        this.loaderService.hideAsyncLoader();
        this.toastService.show(
          'Successfully Added to Address Book.',
          {
            classname: 'bg-success text-light'
          });

          setTimeout(() => {
            // this.modalService.dismissAll();
            this.loaderService.hideAsyncLoader();
            window.location.reload();
          }, 500);
      } catch (err) {
        this.loaderService.hideAsyncLoader();
        if (err.status === 401) {
          return;
        }
        // const hasMessageKey = Object.keys(err.error).includes('message');
        // if (hasMessageKey) {
        //   this.toastService.showDangerToast(err.error.message);
        //   return;
        // }
        const errorFields = Object.keys(err.error.errors);
        errorFields.filter(key => {
          err.error.errors[key].filter(errMessage => {
            this.toastService.showDangerToast(errMessage);
          });
        });
        console.log(err);
      }
    }
  }

  isFormFieldInvalid(formGroup: FormGroup, fieldName) {
    return formGroup.get(fieldName).errors && (formGroup.get(fieldName).dirty || formGroup.get(fieldName).touched);
  }

  fieldErrorMessage(formGroup: FormGroup, fieldName) {
    const isInvalid = this.isFormFieldInvalid(formGroup, fieldName);
    let message: string;
    if (isInvalid) {
      const keys = Object.keys(formGroup.get(fieldName).errors);
      if (formGroup.get(fieldName).errors.pattern) {
        message = formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === EMAIL_REGEX.toString()
          ? 'Please enter a valid email address.'
          : formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === GlobalService.REGEX_PATTERNS.phoneNumber.toString() ? 'Please enter a valid phone number' 
          : formGroup.get(fieldName).errors.pattern.requiredPattern.toString() === GlobalService.REGEX_PATTERNS.telephoneNumber.toString() ? 'Please enter a valid landline number' : 'Please enter a valid name';
      } else if (fieldName === 'landline' && keys.includes('minlength') && keys.includes('maxlength')) {
        message = 'Invalid Landline No.';
      } else if (fieldName === 'recipient_first_name'){
        message = 'First Name is required'
      } else if (fieldName === 'recipient_last_name'){
        message = 'Last Name is required'
      } else if (fieldName === 'recipient_email'){
        message = 'Email is required'
      } else if (fieldName === 'recipient_mobile'){
        message = 'Mobile is required'
      } else if (fieldName === 'name'){
        message = 'Address Name is required'
      } else {
        message = 'This field is required';
      }
    } else {
      message = '';
    }
    return message;
  }


}
