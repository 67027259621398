import Jsona from 'jsona';
import * as i0 from "@angular/core";
const dataFormatter = new Jsona();
export class GlobalService {
    constructor() { }
    getApiErrors(errResponse) {
        if (errResponse.status === 422) {
            const errObj = errResponse.error.errors;
            const errorKeys = Object.keys(errObj);
            const errorValues = [];
            errorKeys.filter(errorKey => {
                errorValues.push(errObj[errorKey][0]);
            });
            return errorValues;
        }
        if (errResponse.status === 401) {
            return ['Unauthorize request'];
        }
        if (errResponse.status === 500) {
            return ['Server Error. Please try again later.'];
        }
    }
}
// API BASE_URL
//prelive AWS
//static BASE_URL_API = 'https://prelive-api.angelspizza.com.ph/api'
//staging AWS
//static BASE_URL_API = 'https://staging-api.angelspizza.com.ph/api'
//production AWS - old Live
GlobalService.BASE_URL_API = 'https://api.angelspizza.com.ph/api';
//static BASE_URL_API = 'https://api.staging.angelspizza.halcyondigitalhost.com/api';
// LOGIN BODY CONFIGß
GlobalService.LOGIN_CLIENT_KEYS = {
    ID_PASSWORD: 3,
    ID_FACEBOOK: 291429762047995,
    SECRET: 'pDfXAreNeorYUvctaNyqzfuhWy6J926IRuNOmjAC',
    SECRET_FACEBOOK: '15bff5b01ebc8ff3d2bf9e9c86824b69'
};
GlobalService.BASE_URL = 'https://angels-pizza-ui-staging.pages.dev/';
// FIELD REGEX
// tslint:disable-next-line: max-line-length
GlobalService.EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Swiper Config
GlobalService.DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal'
};
GlobalService.RE_CAPTCHA_CONFIG = {
    enabled: false,
    siteKey: '6LeoBLQZAAAAAN2pOtaUxQVBuBuk8FPI0QmBgvY6',
};
GlobalService.PHONE_NUMBER = '(02) 8922-2222';
GlobalService.FEATURES = {
    egc: false
};
GlobalService.REGEX_PATTERNS = {
    phoneNumber: /^(09|\+639)\d{9}$/,
    telephoneNumber: /^(02)(\d{7}|\d{8})$/,
};
GlobalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(); }, token: GlobalService, providedIn: "root" });
