<!-- promo ad -->
<!-- <div class="promo-ad" *ngIf="(router.url === '/')">
  <div class="wrapper text-center">
    <p class="cabin-b m-0">For Lazada customers, click on "USE MY COUPON". For more information, please call or text: 09176250771 or 09150688453.</p>
  </div>
</div> -->

<!-- header -->
<!-- <nav class="nav navbar navbar-expand-xl">
  <a routerLink="/" [class.active]="router.url === '/'" class="navbar-brand" (click)="closeModal()">
    <img [src]="appService?.siteSettings?.site_logo" alt="Site Logo" class="img-fluid" *ngIf="appService?.siteSettings?.site_logo">
  </a> -->

<!-- mobile header buttons -->
<!-- <div class="d-xl-none">
    <button class="btn text-primary nav--buttons" (click)="toggleMenu()">
      <span class="custom-icon-menu"></span>
    </button>
    <button class="btn text-primary nav--buttons" (click)="toggleCollapsibleCart()">
      <span class="icon-with-badge">
        <span class="icon custom-icon-cart"></span>
        <span class="badge badge-pill bg-primary text-light"> {{this.cartService.cartTotalQuantity() || 0}} </span>
      </span>
    </button>
  </div> -->

<!-- main navigation -->
<!-- <div class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a [routerLink]="['/use-my-coupon/1']" class="nav-link p-0" (click)="closeModal()">
          <button class="btn btn-coupon">Use My Coupon</button>
        </a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/menu/1']" class="nav-link pr-0" (click)="closeModal()">
          <img src="assets/imgs/wz_orderNow_150x59.gif" alt="" class="btn-ordernow img-fluid">
        </a>
      </li>
      <ng-container *ngFor="let menu of menus">
        <li class="nav-item" [class.dropdown]="menu?.children">
          <a class="nav-link" [class.dropdown-toggle]="menu?.children" [routerLink]="menu.slug" [class.nav-disabled]="menu?.target=='_child'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: (menu.slug == '/' ? true : false)}"
            (click)="closeModal()">{{menu.title}}</a>
          <div class="dropdown-menu" *ngIf="menu?.children">
            <div *ngFor="let children of menu?.children">
              <a class="dropdown-item" *ngIf="children.segment"
                [routerLink]="[menu.slug, children.segment]" [class.active]="router.url.includes(children.segment)"
                (click)="closeModal()">{{children?.title}}</a>
              <a class="dropdown-item" *ngIf="children.slug"
                [routerLink]="[children.slug]" [class.active]="router.url.includes(children.slug)"
                (click)="closeModal()">{{children?.title}}</a>
            </div>
          </div>
        </li>
      </ng-container>
      <li class="nav-item" *ngIf="!userData.user"><a class="nav-link" routerLink="/login"
          routerLinkActive="active">Login</a></li>
      <li class="nav-item" *ngIf="!userData.user"><a class="nav-link" routerLink="/register"
          routerLinkActive="active">Register</a></li>
      <li class="nav-item" *ngIf="userData.user"><a class="nav-link" routerLink="/account-dashboard"
          routerLinkActive="active">Hi,
          {{userData?.user?.profile?.first_name}}</a></li>
      <li class="nav-item" *ngIf="userData.user"><a class="nav-link" href="javascript:void(0)"
          (click)="logoutUser()">Logout</a></li> -->
<!-- <li class="nav-item"><a class="nav-link" href="https://giftaway.ph/wingzone" target="_blank">SEND AS GIFT</a></li> -->
<!-- <li class="nav-item pl-2">
        <button class="btn btn-primary nav--buttons" (click)="toggleCollapsibleCart()">
          <span class="icon-with-badge">
            <span class="icon custom-icon-cart"></span>
            <span class="badge badge-pill bg-light text-primary"> {{this.cartService.cartTotalQuantity() || 0}} </span>
          </span>
          Cart
        </button>
      </li>
    </ul>
  </div>
</nav> -->

<header class="position-sticky" *ngIf="this.currentRoute !== '/'">
  <div class="top-header d-none d-lg-block">
    <div class="container">
      <div class="row">
        <div class="col">(02) 8922-2222</div>
        <div class="col-6 ml-auto top-header-container">
          <ul>            
            <li *ngIf="!userData.user">
              <!-- <a routerLink="/login" id="headerLoginBtn">Log in</a> -->
              <a (click)="openSignInModal()" id="headerLoginBtn">Log in</a>
            </li>
            <li *ngIf="!userData.user">
              <!-- <a routerLink="/register" id="headerSignUpBtn">Sign up</a> -->
              <a (click)="open(signupmodal, 'lg')" id="headerSignUpBtn"
                >Sign up</a
              >
            </li>            
            <li *ngIf="userData.user">
              <a routerLink="/favorite" id="headerFavorites"><span class="custom-icon-heart"></span>My Favorites</a>
            </li>
            <li *ngIf="userData.user">
              <a routerLink="/account-dashboard" id="headerProfile">Profile</a>
            </li>
            <li *ngIf="userData.user">
              <a (click)="logoutUser()" id="headerLogoutBtn">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="main-header d-none d-lg-block">
    <div class="container">
      <div class="row">
        <div class="col-2 logo">
          <a
            routerLink="/"
            [class.active]="router.url === '/'"
            (click)="closeModal()"
            ><img              
              [src]="appService?.siteSettings?.site_logo"
              alt="Site Logo"
              *ngIf="appService?.siteSettings?.site_logo"
          /></a>
        </div>
        <div class="col-10 navigation">
          <ul>
            <ng-container *ngFor="let menu of menus">
              <li [class.dropdown]="menu.children">
                <a
                  class="nav-link"
                  [class.nav-active]="
                    menu?.slug !== '/' && currentRoute?.includes(menu?.slug)
                  "
                  [class.dropdown-toggle]="
                    menu?.children && menu?.children?.length > 0
                  "
                  [routerLink]="
                    menu.slug +
                    (menu?.slug === '/menu/1' &&
                    menu?.children?.length > 1 &&
                    menu?.children[1]?.segment
                      ? '/' + menu?.children[1]?.segment
                      : '')
                  "
                  [class.nav-disabled]="menu?.target == '_child'"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{
                    exact: menu.slug == '/' ? true : false
                  }"
                  (click)="closeModal()"
                  >{{ menu.title }}</a
                >
                <div class="dropdown-menu" *ngIf="menu?.children">
                  <div *ngFor="let children of menu?.children">
                    <a
                      class="dropdown-item"
                      *ngIf="children.segment"
                      [routerLink]="[menu.slug, children.segment]"
                      [class.active]="router.url.includes(children.segment)"
                      (click)="closeModal()"
                      >{{ children?.title }}</a
                    >
                    <a
                      class="dropdown-item"
                      *ngIf="children.slug"
                      [routerLink]="[children.slug]"
                      [class.active]="router.url.includes(children.slug)"
                      (click)="closeModal()"
                      >{{ children?.title }}</a
                    >
                  </div>
                </div>
              </li>
            </ng-container>
            <!-- <li><a href="http://uatangelspizza.sttomas.com/menu">Menu</a></li>
            <li>
              <a href="http://uatangelspizza.sttomas.com/stores">Stores</a>
            </li>
            <li><a href="http://uatangelspizza.sttomas.com/promos">News</a></li>
            <li>
              <a href="http://uatangelspizza.sttomas.com/apc-card">APC Card</a>
            </li>
            <li>
              <a href="http://uatangelspizza.sttomas.com/about">Our Story</a>
            </li>
            <li>
              <a href="http://uatangelspizza.sttomas.com/stores">Contact Us</a>
            </li> -->
            <!-- <li>
              <a (click)="toggleCollapsibleCart()" id="boxCounter3" class="box"
                >My Cart
                <span
                  >({{ this.cartService.cartTotalQuantity() || 0 }})</span
                ></a
              >
            </li> -->
            <li>
              <a routerLink="/menu/1" id="boxCounter3" class="box"
                >My Cart
                <span
                  >({{ this.cartService.cartTotalQuantity() || 0 }})</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="home-banner other-banner multi-serve">
    <img class="b-lazy img-fluid w-100"
    src=data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==
    data-src="images/stores-banner.jpg"
    data-src-mobile="images/stores-banner.jpg"
    data-src-tablet="images/stores-banner.jpg"
    data-src-desktop="images/stores-banner-desktop.jpg" alt="Stores Banner" />
  </div> -->
</header>

<!-- home header -->
<header class="home position-absolute w-100" *ngIf="this.currentRoute === '/'">
  <div class="top-header d-none d-lg-block header-home">
    <div class="container">
      <div class="row">
        <div class="col-6 ml-auto top-header-container">
          <ul>
                        
            <li *ngIf="!userData.user">
              <!-- <a routerLink="/login" id="headerLoginBtn">Log in</a> -->
              <a (click)="openSignInModal()" #headerLoginBtn>Log in</a>
            </li>
            <li *ngIf="!userData.user">
              <!-- <a routerLink="/register" id="headerSignUpBtn">Sign up</a> -->
              <a (click)="open(signupmodal, 'lg')" #headerSignUpBtn
                >Sign up</a
              >
            </li>
            <li *ngIf="userData.user">
              <a routerLink="/favorite" id="headerFavorites"><span class="custom-icon-heart"></span>My Favorites</a>
            </li>            
            <li *ngIf="userData.user">
              <a routerLink="/account-dashboard" id="headerProfile">Profile</a>
            </li>
            <li *ngIf="userData.user">
              <a (click)="logoutUser()" id="headerLogoutBtn">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="main-header d-none d-lg-block header-home">
    <div class="container">
      <div class="row">
        <div class="col-5 contact">(02) 8922-2222</div>
        <div class="col-2 logo">
          <a href="/"
            ><img
              [src]="appService?.siteSettings?.site_logo"
              alt="Site Logo"
              *ngIf="appService?.siteSettings?.site_logo"
          /></a>
        </div>
        <div class="col-5 navigation">
          <ul>
            <ng-container *ngFor="let menu of menus">
              <!-- <li
                [class.dropdown]="menu.children"
                *ngIf="menu.slug === '/menu/1'"
              > -->
              <li
                [class.dropdown]="menu.children"
                *ngIf="menu.slug === '/locations' || menu.slug === '/menu/1'"
              >
                <a
                  class="nav-link"
                  [class.dropdown-toggle]="menu?.children"
                  [routerLink]="
                    menu.slug +
                    (menu?.slug === '/menu/1' &&
                    menu?.children?.length > 1 &&
                    menu?.children[1]?.segment
                      ? '/' + menu?.children[1]?.segment
                      : '')
                  "
                  [class.nav-disabled]="menu?.target == '_child'"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{
                    exact: menu.slug == '/' ? true : false
                  }"
                  (click)="closeModal()"
                  >{{ menu.title }}</a
                >
                <div class="dropdown-menu" *ngIf="menu?.children">
                  <div *ngFor="let children of menu?.children">
                    <a
                      class="dropdown-item home-navlink-children"
                      *ngIf="children.segment"
                      [routerLink]="[menu.slug, children.segment]"
                      [class.active]="router.url.includes(children.segment)"
                      (click)="closeModal()"
                      >{{ children?.title }}</a
                    >
                    <a
                      class="dropdown-item home-navlink-children"
                      *ngIf="children.slug"
                      [routerLink]="[children.slug]"
                      [class.active]="router.url.includes(children.slug)"
                      (click)="closeModal()"
                      >{{ children?.title }}</a
                    >
                  </div>
                </div>
              </li>
            </ng-container>
            <!-- <li>
              <a (click)="toggleCollapsibleCart()" id="boxCounter3" class="box"
                >My Cart
                <span
                  >({{ this.cartService.cartTotalQuantity() || 0 }})</span
                ></a
              >
            </li> -->
            <li>
              <a routerLink="/menu/1" id="boxCounter3" class="box"
                >My Cart
                <span
                  >({{ this.cartService.cartTotalQuantity() || 0 }})</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="home-slider home-banner multi-serve">
    <div>
      <img class="b-lazy img-fluid w-100"
      src=data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==
      data-src="images/slider/spinachdip-widescreen.jpg"
      data-src-mobile="images/slider/spinachdip-mobile.jpg"
      data-src-tablet="images/slider/spinachdip-tablet.jpg"
      data-src-desktop="images/slider/spinachdip-regular.jpg" alt="Home Banner"
      />
    </div>
    <div>
      <img class="b-lazy img-fluid w-100"
      src=data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==
      data-src="images/slider/GardenEden-widescreen.jpg"
      data-src-mobile="images/slider/GardenEden-mobile.jpg"
      data-src-tablet="images/slider/GardenEden-mobile-Tablet.jpg"
      data-src-desktop="images/slider/GardenEden-regular.jpg" alt="Home Banner"
      />
    </div>
    <div>
      <img class="b-lazy img-fluid w-100"
      src=data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==
      data-src="images/slider/barkada-wide.jpg"
      data-src-mobile="images/slider/barkada-mobile.jpg"
      data-src-tablet="images/slider/barkada-tablet.jpg"
      data-src-desktop="images/slider/Barkada-regular.jpg" alt="Home Banner" />
    </div>
  </div> -->
</header>
<!-- home header end -->

<div
  class="mobile-header display-on-mobile"
  [class.titleheader]="currentRoute === '/'"
>
  <div class="container">
    <div class="logo">
      <a routerLink="/" (click)="toggleMobileMenu(false)"
        ><img
          [src]="appService?.siteSettings?.site_logo"
          alt="Site Logo"
          *ngIf="appService?.siteSettings?.site_logo"
      /></a>
    </div>
    <div class="mobile-nav">
      <a (click)="toggleMobileMenu(true)"><span class="custom-icon-menu"></span></a>
    </div>
  </div>
</div>
<div
  id="mobilenavigation"
  class="mobile-navigation display-on-mobile"
  [class.show]="showMobileMenu"
>
  <a (click)="toggleMobileMenu(false)" class="mobile-navigation-close">
    <span class="custom-icon-delete mt-2" style="font-size:x-large;"></span>
  </a>
  <ul>
    <li>
      <div class="mx-5 px-5 mb-4">
        <a routerLink="/"
          ><img
            class="img-fluid"
            [src]="appService?.siteSettings?.site_logo"
            alt="Site Logo"
            *ngIf="appService?.siteSettings?.site_logo"
        /></a>
      </div>
    </li>
    <li *ngFor="let menu of menus">
      <a *ngIf="menu?.children?.length <= 0"
        [routerLink]="menu?.slug"
        (click)="toggleMobileMenu(false)"
        >{{ menu.title }}</a
      >
      <ng-container *ngIf="menu?.children?.length > 0">
        <a (click)="isExpanded[menu?.title] = !isExpanded[menu?.title]" [attr.aria-expanded]="isExpanded[menu?.title]" [attr.aria-controls]="menu?.title" style="align-items: center;">{{ menu.title }}
          <span style="height: .5em; width: .5em;" class="ml-2 custom-icon-arrow-down"></span></a>
        <div id="menu?.title" [collapse]="!isExpanded[menu?.title]" [isAnimated]="true">
          <ul>
            <li *ngFor="let child of menu?.children">
              <a [routerLink]="[menu?.slug, child?.segment]" (click)="toggleMobileMenu(false); isExpanded[menu?.title] = !isExpanded[menu?.title]">{{child?.title}}</a>
            </li>
          </ul>
        </div>
      </ng-container>
    </li>
    <li *ngIf="userData.user"><a routerLink="/favorite" (click)="toggleMobileMenu(false)">My Favorites</a></li>
    <!-- <li><a routerLink="/login">Login</a></li> -->
    <li *ngIf="userData.user">
      <a routerLink="/account-dashboard" (click)="toggleMobileMenu(false)" id="headerProfile">Profile</a>
    </li>
    <li *ngIf="userData.user">
      <a (click)="logoutUser()" (click)="toggleMobileMenu(false)" id="headerLogoutBtn">Log Out</a>
    </li>
    <li *ngIf="!userData.user"><a (click)="openSignInModal(); toggleMobileMenu(false)">Login</a></li>
    <!-- <li><a routerLink="/register">Sign up</a></li> -->
    <li *ngIf="!userData.user">
      <a (click)="open(signupmodal, 'lg'); toggleMobileMenu(false)">Sign up</a>
    </li>
    <li><h6 class="mt-4">(02) 8922-2222</h6></li>
  </ul>
  <!-- <div class="overlay" (click)="mobilenav()"></div> -->
  <div class="overlay"></div>
</div>

<!-- LOGIN MODAL -->
<ng-template #signinmodal let-modal id="popuplogin">
  <div class="modal-body">
    <app-login
      (closeModal)="modal.close()"
      (openSignUpModal)="openSignUpModal()"
    ></app-login>
    <!-- <div class="popup-container">
      <div class="popup-innercontainer text-center inputstyle">
        <div class="popup-close font-thin">
          <a (click)="modal.dismiss('Cross click')">Close (x)</a>
        </div>

        <div class="d-flex h-100 align-items-center">
          <form class="w-100 px-md-5 mx-md-4">
            <h6 class="pt-3">Welcome back!</h6>
            <div class="pt-3" id="loaderLogin"></div>
            <div class="pt-3">
              <input
                type="text"
                class="text-center w-100"
                id="loginHeader"
                maxlength="50"
                placeholder="Username or email"
              />
            </div>
            <div class="pt-3">
              <input
                type="password"
                class="text-center w-100"
                id="passHeader"
                placeholder="Password"
              />
            </div>
            <div class="pt-3">
              <a class="btn-green w-100" (click)="loginCustomer()">Proceed</a>
            </div>
            <div class="pt-4">
              <u
                ><a class="color-black font-normal" (click)="popupforgot()"
                  >Forgot Password</a
                ></u
              >
            </div>
          </form>
        </div>
      </div>
    </div> -->
  </div>
</ng-template>

<ng-template #signupmodal let-modal>
  <div class="modal-body">
    <app-register
      (closeModal)="modal.close()"
      (openSignInModal)="openSignInModal()"
    ></app-register>
    <!-- <div class="popup-container w-auto">
      <div class="popup-innercontainer text-center inputstyle">
        <div class="popup-close font-thin"><a (click)="modal.dismiss('Cross click')">Close (x)</a></div>
        <h6 class="py-4">Welcome!</h6>
        <div class="pad-bottom-md mx-lg-5 px-lg-5">
          <label class="width-30" id="label1" placeholder="First name">*Fill up all required fields</label>
          <label class="width-30" id="label2" placeholder="First name">*Password and Confirm Password does not match!</label>
          <div class="row pt-3">
            <div class="col-12 col-lg-6">
              <input type="text" class="w-100 mb-3 mb-lg-0" id="custfname" placeholder="First name"  />
            </div>
            <div class="col-12 col-lg-6">
              <input type="text" class="w-100" id="custlname" placeholder="Last name"  />
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-12 col-lg-6">
              <input type="text" class="w-100 mb-3 mb-lg-0" id="custcon1" onkeypress="return onlyNumberKey(event)" placeholder="Mobile"  />
            </div>
            <div class="col-12 col-lg-6">
              <input type="text" class="w-100" id="custcon2" onkeypress="return onlyNumberKey(event)" placeholder="Landline"  />
              <input type="text" class="w-100" id="custcon3" onkeypress="return onlyNumberKey(event)" placeholder="Landline"  hidden/>
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-12">
              <input type="text" class="w-100" id="custemail" placeholder="Email Address">
            </div>
          </div>
          
          <div class="row pt-3">
            <div class="col-12">
              <input type="text" id="custaddline1" onpaste="return false;"  class="w-100" placeholder="Unit no., Street">
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12 col-lg-6">
              <input type="text" class="w-100 mb-3 mb-lg-0" id="custaddline2" placeholder="Baranggay/Subdivision"  />
            </div>
            <div class="col-12 col-lg-6">
              <input type="text" class="w-100" id="custaddline3" placeholder="City/Municipality"  />
            </div>
          </div>


          <div class="row pt-3">
            <div class="col-12">
              <input type="text" class="w-100" id="landmarking" placeholder="Landmark">
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <input type="text" class="w-100" id="remarking" placeholder="Remarks">
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-12">
              <input type="text" class="w-100" id="useriding" placeholder="Username">
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12 col-lg-6">
              <input type="password" class="w-100 mb-3 mb-lg-0" id="password1" placeholder="Password"  />
            </div>
            <div class="col-12 col-lg-6">
              <input type="password" class="w-100" id="conpass" placeholder="Confirm Password"  />
            </div>
          </div>
          <div class="col-xl-12 col-lg-2 col-md-12 col-sm-12 col-12 text-center text-center-on-mobile" id="registrationLoader"></div>
          <div class="pt-4 mx-auto"><a href="#" (click)="validateCustomerInputs()" class="btn-green w-100 mx-auto">Proceed</a></div>
        </div>

        
      </div>
    </div> -->
  </div>
</ng-template>
<section class="block-banner" *ngIf="this.currentRoute === '/'">
  <div
    *ngIf="homeBanners?.media"
    [disabled]="homeBanners?.media?.length <= 1"
    [swiper]="swiperConfig.bannerSlider"
    class="
      swiper-container swiper-container-banner
      home-slider home-banner
      multi-serve
    "
    style="height: 100%"
  >
    <!-- <div
    [hidden]="!homeBanners?.media"
    [disabled]="homeBanners?.media?.length <= 1"
    [swiper]="swiperConfig.bannerSlider"
    class="
      swiper-container swiper-container-banner
      home-slider home-banner
      multi-serve
    "
    style="height: 100%"
  > -->
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        style="min-width: 100%; min-height: 100%"
        *ngFor="let banner of homeBanners?.media"
      >
        <!-- <a href="{{ banner | image: 'url' }}"> </a> -->

        <picture>
          <!-- [routerLink]="['menu', '1']" -->
          <source
            media="(min-width: 1200px)"
            [attr.data-srcset]="banner | image: 'src':'desktop'"
          />
          <source
            media="(min-width: 576px)"
            [attr.data-srcset]="banner | image: 'src':'tablet'"
          />
          <source
            media="(max-width: 576px)"
            [attr.data-srcset]="banner | image: 'src':'mobile'"
          />
          <img
            fetchpriority="high"            
            class="swiper-lazy"
            style="
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: bottom;
              max-height: 900px;
            "
            [attr.data-src]="banner | image: 'src':'desktop'" [alt]="banner | image: 'alt'"
          />
          <!-- <div class="swiper-lazy-preloader">
            <img
              [src]="'/assets/imgs/angels/bg-popup.png' | image: 'relative'"
            />
          </div> -->
          <!-- <img
            class="swiper-lazy"
            style="
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: bottom;
              max-height: 900px;
            "
            [defaultImage]="'/assets/imgs/angels/bg-popup.png' | image: 'relative'"
            [lazyLoad]="banner | image: 'src':'desktop'"
            [alt]="banner | image: 'alt'"
          /> -->
          <!-- <div class="swiper-lazy-preloader"></div> -->
        </picture>
        <!-- <div class="swiper-lazy-preloader"></div> -->
      </div>
    </div>
    <!-- <div
      class="swiper-pagination"
      [hidden]="homeBanners?.media?.length <= 1"
    ></div> -->
  </div>
</section>
