<aside class="sidemenu" [class.open]="state" (click)="onCloseMenu()">
    <div class="sidemenu__content" (click)="$event.stopPropagation();">
        <ul>
            <li>
                <a *ngIf="!userData?.user" routerLink="/login" (click)="onCloseMenu()">Login</a>
                <a *ngIf="!userData?.user" routerLink="/register" (click)="onCloseMenu()">Register</a>
                <a *ngIf="userData?.user" routerLink="/account-dashboard" (click)="onCloseMenu()">Hi,
                    {{userData?.user?.profile?.first_name}}</a>
                <a *ngIf="userData?.user" href="javascript:void(0)" (click)="logoutUser()">Logout</a>
                <span class="custom-icon-delete ml-auto" (click)="onCloseMenu()"></span>
            </li>
            <!-- <li>
                <label class="justify-content-center" (click)="navigate('/menu/1')">
                    <img src="assets/imgs/wz_orderNow_150x59.gif" alt="" class="img-fluid">
                </label>
            </li> -->
            <li *ngFor="let menu of menus">
                <label [class.active]="menu.slug === '/' ? router.url === '/' : router.url.includes(menu.slug)"
                    (click)="menu?.children ? (activeSub = menu.slug) : navigate(menu.slug)">{{menu.title}} <span
                        class="custom-icon-arrow-right ml-auto" *ngIf="menu?.children"></span></label>
                <ul class="sidemenu__sub" [class.open]="activeSub == menu.slug">
                    <li (click)="activeSub = ''"><span class="custom-icon-arrow-left"></span>Back</li>
                    <li *ngIf="menu?.target == '_self'"><label [class.active]="router.url === menu.slug"
                            (click)="navigate(menu.slug)">{{menu?.title}}</label></li>
                    <li *ngFor="let children of menu.children"><label
                            [class.active]="router.url.includes(children.segment)"
                            (click)="navigate(menu.slug + '/' + children.segment)">{{children.title}}</label></li>
                </ul>
            </li>
            <!-- <li>
                <label>
                    <a href="https://giftaway.ph/wingzone" target="_blank">Send as GIFT</a>
                </label>
            </li> -->
        </ul>
    </div>
</aside>