import { ToastService } from './../../services/toast/toast.service';
import { Component, OnInit, TemplateRef, HostBinding } from '@angular/core';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host:  {'[class.ngb-toasts]': 'true'}
})
export class ToastContainerComponent implements OnInit {

  constructor(public toastService: ToastService) { }

  ngOnInit() {
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  close(index) {
    this.toastService.toasts.splice(index, 1);
  }
}
