import { CartService } from 'src/app/services/cart/cart.service';
import { UserService } from './services/user/user.service';
import { StoreService } from './services/store/store.service';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent, HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { ToastService } from './services/toast/toast.service';
import { Router } from '@angular/router';
import { Injectable, NgModule, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { LoaderService } from './services/loader.service';


@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private storeService: StoreService,
    private userService: UserService,
    private cartService: CartService,
    private loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    if (this.userService.isStopRequest) {
      this.loaderService.isLoading.next(false);
      return EMPTY;
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, async (err: any) => {
      if (err instanceof HttpErrorResponse) {
        // do stuff when http request error (404, 401, 500, etc)
        // recommended to pass the {{err.status}} in subject/observable for subscription
        if (isPlatformBrowser(this.platformId)) {
          console.log(err.status);
          if (err.status === 500) {            
            this.toastService.showDangerToast('Server Error, Please try again later.');
            //this.router.navigate(['/under-maintenance'])                       
          }
          if (err.status === 503) {            
            //this.toastService.showDangerToast('Server Error, Please try again later.');
            this.router.navigate(['/under-maintenance'])                       
          }
          if (err.status === 401) {
            if (!err.url.includes('/oauth/token')) {
              // const refreshToken = await this.storeService.getItem('refresh_token');
              // if (refreshToken) {

              //   const res = await this.userService.refreshToken();
              //   switch (res) {
              //     case 'success':
              //       window.location.reload();
              //       break;
              //     case 'revoked':
              //       this.userService.revokeAuth();
              //       break;
              //   }
              //   return;

              // }              
              this.userService.revokeAuth();
              
            } else {
              this.toastService.show('Wrong email or password.', { classname: 'bg-danger text-light' });
            }
          }
        }
      }
    }), finalize(()=>{this.loaderService.isLoading.next(false);}));
  }
}
@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
  ]
})
export class ResponseInterceptorModule { }
