<div class="main-content relative">
<!-- <div class="main-content relative" *ngIf="!showAsyncLoader && !showLoader"> -->
  <app-header [userData]="userData" (logout)="logout()" (openMenu)="toggleMenu(true)" (toggleCart)="toggleCart()"
    [menus]="menus" class="header">
  </app-header>

  <app-cart [cartState]="isCartOpen" (closeCart)="closeCart($event)" (emptyCart)="emptyCart()"></app-cart>

  <app-sidemenu [userData]="userData" (logout)="logout()" [menuState]="menuState" [menus]="menus"
    (closeMenu)="toggleMenu(false)">
  </app-sidemenu>

  <router-outlet></router-outlet>
</div>

<app-footer *ngIf="!showLoader"></app-footer>

<app-toast-container class="toast-header-margin"></app-toast-container>

<app-back-to-top *ngIf="!(router.url === '/')" [routerUrl]="router.url"></app-back-to-top>

<div class="page-loader primary" *ngIf="showAsyncLoader">
  <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="page-loader light" *ngIf="showLoader">
  <img [src]="appService?.siteSettings?.site_logo" alt="Loading" class="img-fluid img-loader" *ngIf="appService?.siteSettings?.site_logo">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<!-- <div class="page-loader light" *ngIf="loaderService?.isLoading | async">
  <img [src]="appService?.siteSettings?.site_logo" alt="Loading" class="img-fluid img-loader" *ngIf="appService?.siteSettings?.site_logo">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div> -->