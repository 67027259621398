import { StoreService } from './../store/store.service';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BranchLocationService {
  branch: any;
  branchLocation: any;
  provinces: any;
  cities: any;
  barangays: any;
  branches: any;
  profileLocations: any;
  isUser = false;
  defaultStoresUrl;
  constructor(
    private apiService: ApiService,
    private storeService: StoreService
  ) { }

  async getProvinces() {
    if (this.provinces) {
      return this.provinces;
    }
    try {
      const provinces = await this.apiService.request({
        method: 'GET',
        url: '/provinces',
        jsona: true
      });
      this.provinces = provinces;
      return provinces;
    } catch (err) {
      console.log(err);
    }
  }

  async getCities(province) {
    console.log(province)
    if (this.cities) {
      return this.cities;
    }

    if (!province) {
      return;
    }

    try {
      const cities = await this.apiService.request({
        method: 'GET',
        url: `/provinces/${province}?include=cities`,
        jsona: true
      });
      this.cities = cities;
      return cities;
    } catch (err) {
      console.log(err);
    }
  }

  async getBarangays(city) {
    if (this.barangays) {
      return this.barangays;
    }

    if (!city) {
      return;
    }

    try {
      const barangays = await this.apiService.request({
        method: 'GET',
        url: `/cities/${city}?include=barangays`,
        jsona: true
      });
      this.barangays = barangays;
      return barangays;
    } catch (err) {
      console.log(err);
    }
  }

  async getBranches(barangay) {
    if (this.branches) {
      return this.branches;
    }

    if (!barangay) {
      return;
    }

    try {
      const branches = await this.apiService.request({
        method: 'GET',
        url: `/barangays/${barangay}?include=branches`,
        jsona: true
      });
      this.branches = branches;
      return branches;
    } catch (err) {
      console.log(err);
    }
  }

  async getProfileLocations() {
    if (this.profileLocations) {
      return this.profileLocations;
    }
    try {
      const profileLocations = await this.apiService.request({
        method: 'GET',
        url: '/profile-locations',
        jsona: true
      });
      this.profileLocations = profileLocations;
      return profileLocations;
    } catch (err) {
      console.log(err);
    }
  }

  async setUserBranchLocation(isLogin?) {
    const deliveryType = await this.storeService.getItem('deliveryType');

    if (deliveryType === 'pick-up') {
      return;
    }

    const user: any = await this.storeService.getItem('user');
    const branchLocation: any = await this.storeService.getItem('branchLocation');
    if (!user) {
      return;
    }
    if (branchLocation && !isLogin) {
      if (!branchLocation.isUser) {
        return;
      }
    }
    if (!user.profile.province || !user.profile.city || !user.profile.barangay) {
      return;
    }
    const provinces = await this.getProvinces();
    // const cities = await this.getCities();
    // const barangays = await this.getBarangays();
    const userProfileLocations = {
      province: user.profile.province,
      city: user.profile.city,
      barangay: user.profile.barangay,
      address_1: user.profile.address.address_1,
      company: user.profile.address.company,
      last_name: user.profile.last_name,
      first_name: user.profile.first_name,
      email: user.email,
      mobile: user.profile.address.mobile,
      id: user.profile.address.id
    };

    console.log(user);    

    const filteredBranchLocation = {
      branch: null,
      brgy: null,
      city: null,
      province: provinces,
      selected_branch: null,
      isUser: true
    };

    filteredBranchLocation.city = filteredBranchLocation.province.filter(province =>
      province.id === userProfileLocations.province.id)[0];
    console.log(filteredBranchLocation.city);

    filteredBranchLocation.city = await this.getCities(filteredBranchLocation.city.id);

    if (!filteredBranchLocation.city) {
      return
    }

    filteredBranchLocation.brgy = filteredBranchLocation.city.cities.filter(city =>
      city.id === userProfileLocations.city.id)[0];

    filteredBranchLocation.brgy = await this.getBarangays(filteredBranchLocation.brgy.id);

    if (!filteredBranchLocation.brgy) {
      return
    }

    filteredBranchLocation.branch = filteredBranchLocation.brgy.barangays.filter(brgy =>
      brgy.id === userProfileLocations.barangay.id)[0];

    if (filteredBranchLocation.branch && filteredBranchLocation.branch.id) {
      filteredBranchLocation.branch = await this.getBranches(filteredBranchLocation.branch.id);

      filteredBranchLocation.selected_branch = filteredBranchLocation.branch.branches[0];
      await this.storeService.setItem('savedAddress', userProfileLocations)
      await this.storeService.setItem('branchLocation', filteredBranchLocation);
      await this.storeService.setItem('branch', filteredBranchLocation.selected_branch.segment);
      await this.storeService.setItem('brgy_segment', filteredBranchLocation.branch.segment);
      this.branch = filteredBranchLocation.selected_branch.segment;
    }

    this.branchLocation = filteredBranchLocation;
  }

  async getBranchData(branchSegment) {
    try {
      const selectedBranch = await this.apiService.request({
        method: 'GET',
        url: `/branches/${branchSegment}`,
        jsona: true
      });
      const branchLocation: any = await this.storeService.getItem('branchLocation');
      branchLocation.selected_branch = selectedBranch;
      await this.storeService.setItem('branchLocation', branchLocation);
    } catch (err) {
      console.log(err);
    }
  }

  async setServiceBranch() {
    this.branch = await this.storeService.getItem('branch');
    this.branchLocation = await this.storeService.getItem('branchLocation');
  }

  async getStoresUrl() {
    const provinces: any = await this.apiService.request({
      method: 'GET',
      url: '/province-branches-for-location-page',
      jsona: true
    })
    console.log('provinces => ', provinces);

    if (!provinces || provinces.length <= 0) return;

    this.defaultStoresUrl = `/locations/${provinces[0].id}`;
  }
  
}
