<div class="component-wrapper">
    <div class="modal-header">
        <div class="d-flex">
            <h2 class="title">Check if we deliver in your area</h2>
            <a class="btn" (click)="closeModal()">
                <span class="acc-close custom-icon-delete"></span>
            </a>
        </div>
        <hr>
    </div>
    <form [formGroup]="branchForm">
        <div class="modal-body location">
            <!-- <div class="form-group px-lg-5 d-flex address">
                <div class="address-wrapper">
                    <input [attr.disabled]="user?.addresses?.length || null" maxlength="150"
                        formControlName="fullAddress" class="address-input form-control"
                        placeholder="Enter Street Number and Name" type="search">
                </div>
                <div class="submit-btn">
                    <button class="btn btn-confirm btn-main" [disabled]="!branchForm.valid"
                        (click)="setSelectedBranch()">Submit</button>
                </div>
            </div> -->
            <div class="dropdown-location">
                <div ngbDropdown class="d-inline-block" placement="bottom">
                    <button class="btn btn-outline" id="dropdownProvince" ngbDropdownToggle
                        [disabled]="!branchForm.get('province').value || disableDropdownButtons">
                        <span>
                            {{branchForm.get('city').value?.title || 'Select Province'}}
                        </span>
                        <span class="custom-icon-arrow-left rotate"></span>

                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownProvince">
                        <ng-container *ngFor="let province of branchForm.get('province').value">
                            <button ngbDropdownItem (click)="setBranch(province, 'city')">
                                {{province?.title}}
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div ngbDropdown class="d-inline-block" placement="bottom">
                    <button class="btn btn-outline" id="dropdownCity" ngbDropdownToggle
                        [disabled]="!branchForm.get('city').value || disableDropdownButtons">
                        <span>
                            {{branchForm.get('brgy').value?.title || 'Select City'}}
                        </span>
                        <span class="custom-icon-arrow-left rotate"></span>

                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownCity">
                        <button ngbDropdownItem *ngFor="let city of branchForm.get('city').value?.cities"
                            (click)="setBranch(city, 'brgy')"> {{city?.title}} </button>
                    </div>
                </div>
                <div ngbDropdown class="d-inline-block" placement="bottom">
                    <button class="btn btn-outline" id="dropdownBrgy" ngbDropdownToggle
                        [disabled]="!branchForm.get('brgy').value || disableDropdownButtons">
                        <span>
                            {{branchForm.get('branch').value?.title || 'Select Barangay'}}
                        </span>
                        <span class="custom-icon-arrow-left rotate"></span>

                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBrgy">
                        <button ngbDropdownItem *ngFor="let brgy of branchForm.get('brgy').value?.barangays"
                            (click)="setBranch(brgy, 'branch')"> {{brgy?.title}} </button>
                    </div>
                </div>
            </div>

            <!-- <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline" id="dropdownBranch" ngbDropdownToggle
                    [disabled]="!branchForm.get('branch').value">
                    {{branchForm.get('selected_branch').value?.title || 'Select Branch'}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBranch">
                    <button ngbDropdownItem *ngFor="let branch of branchForm.get('branch').value?.branches"
                        (click)="setBranch(branch, 'selected_branch')"> {{branch?.title}} </button>
                </div>
            </div> -->

            <div>
                <button class="btn btn-primary text-capitalize"
                    [ngClass]="{'btn-disabled': !branchForm?.valid, 'd-none': disableDropdownButtons, 'btn-saved d-block': savedSuccess}"
                    (click)="setSelectedBranch()">
                    <!--btn-saved, btn-disabled-->
                    <span class="custom-icon-check"></span>
                    Save My Location
                </button>
                <button class="btn btn-primary btn-yellow text-capitalize"
                    [ngClass]="{'btn-disabled': !branchForm?.valid, 'd-none': !disableDropdownButtons}"
                    (click)="removeDisabledState()">
                    <!--display when changing location -->
                    Change My Location
                </button>
            </div>
        </div>
        <!-- <div class="form-group px-5 d-flex note">
            <small>The items in your cart will be removed after changing the branch.</small>
        </div> -->

    </form>
</div>