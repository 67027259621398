<!-- <div class="block-register page-content">
  <div class="block-content">
    <div class="container block-wrapper block--padding">
      <div class="block__title title">
        <h2 class="section--title text-primary mb-4">Register</h2>
      </div> -->
<div class="popup-container w-auto border-radius-primary overflow-hidden h-100">
  <div class="block-details popup-innercontainer text-center inputstyle">
    <div class="popup-close font-thin">
      <a (click)="closeModal.emit()">Close (x)</a>
    </div>
    <h6 class="py-4">Welcome!</h6>
    <form
      class="pad-bottom-md mx-lg-5 px-2 px-lg-5"
      (ngSubmit)="register()"
      [formGroup]="registerForm"
    >
      <!-- <h3 class="text-primary zilla-b mb-4">Delivery Address</h3> -->
      <b class="mb-5">Delivery Address</b>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Address 1*</label>
            <input
              maxlength="150"
              class="form-control"
              type="text"
              placeholder="Floor, unit no., building/no. and street"
              formControlName="delivery_address_1"
              [class.is-invalid]="
                fieldErrorMessage(registerForm, 'delivery_address_1')
              "
            />
            <div class="invalid-feedback">
              {{ fieldErrorMessage(registerForm, "delivery_address_1") }}
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Province*</label>

            <div ngbDropdown>
              <button
                [attr.disabled]="!this.branchLocation.delivery_state || null"
                class="btn form-dropdown"
                ngbDropdownToggle
              >
                <label>{{
                  registerForm.get("delivery_city").value?.title ||
                    "Select Province"
                }}</label>
              </button>
              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  *ngFor="let province of this.branchLocation.delivery_state"
                  (click)="setBranch(province, 'delivery_city')"
                >
                  {{ province?.title }}
                </button>
              </div>
            </div>

            <!-- <select [attr.disabled]="!this.branchLocation.delivery_state || null" class="form-control"
                  placeholder="Enter here" formControlName="delivery_state"
                  (change)="setBranch(registerForm.get('delivery_state').value, 'delivery_city')">
                  <option *ngFor="let province of this.branchLocation.delivery_state" [ngValue]="province">
                    {{province?.title}}
                  </option>
                </select> -->

            <div class="invalid-feedback">
              {{ fieldErrorMessage(registerForm, "delivery_state") }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>City*</label>
            <div ngbDropdown>
              <button
                [attr.disabled]="
                  !this.registerForm.get('delivery_city')?.value || null
                "
                class="btn form-dropdown"
                ngbDropdownToggle
              >
                <label>{{
                  registerForm.get("delivery_barangay").value?.title ||
                    "Select City"
                }}</label>
              </button>
              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  *ngFor="
                    let city of this.registerForm.get('delivery_city')?.value
                      ?.cities
                  "
                  (click)="setBranch(city, 'delivery_barangay')"
                >
                  {{ city?.title }}
                </button>
              </div>
            </div>

            <!-- <select [attr.disabled]="!this.branchLocation.delivery_city || null" class="form-control"
                  placeholder="Enter here" formControlName="delivery_city"
                  (change)="setBranch(registerForm.get('delivery_city').value, 'delivery_barangay')">
                  <option *ngFor="let city of this.branchLocation.delivery_city" [ngValue]="city">
                    {{city?.title}}
                  </option>
                </select> -->
            <div class="invalid-feedback">
              {{ fieldErrorMessage(registerForm, "delivery_city") }}
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>Barangay*</label>
            <div ngbDropdown>
              <button
                [attr.disabled]="
                  !this.registerForm.get('delivery_barangay')?.value || null
                "
                class="btn form-dropdown"
                ngbDropdownToggle
              >
                <label>{{
                  registerForm.get("branch").value?.title || "Select Barangay"
                }}</label>
              </button>
              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  *ngFor="
                    let brgy of this.registerForm.get('delivery_barangay')
                      ?.value?.barangays
                  "
                  (click)="setBranch(brgy, 'branch')"
                >
                  {{ brgy?.title }}
                </button>
              </div>
            </div>

            <!-- <select [attr.disabled]="!this.branchLocation.delivery_barangay || null" class="form-control"
                  placeholder="Enter here" formControlName="delivery_barangay"
                  (change)="setBranch(registerForm.get('delivery_barangay').value, 'branch')">
                  <option *ngFor="let brgy of this.branchLocation.delivery_barangay" [ngValue]="brgy">
                    {{brgy?.title}}
                  </option>
                </select> -->
            <div class="invalid-feedback">
              {{ fieldErrorMessage(registerForm, "delivery_barangay") }}
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label>Company</label>
            <input
              maxlength="50"
              class="form-control"
              type="text"
              placeholder="Enter here"
              formControlName="delivery_company"
              [class.is-invalid]="
                fieldErrorMessage(registerForm, 'delivery_company')
              "
            />
            <div class="invalid-feedback">
              {{ fieldErrorMessage(registerForm, "delivery_company") }}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="form-group col-md-6">
          <label for="">First Name*</label>
          <input
            maxlength="50"
            type="text"
            class="form-control"
            formControlName="first_name"
            [class.is-invalid]="fieldErrorMessage(registerForm, 'first_name')"
          />
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "first_name") }}
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="">Last Name*</label>
          <input
            maxlength="50"
            type="text"
            class="form-control"
            formControlName="last_name"
            [class.is-invalid]="fieldErrorMessage(registerForm, 'last_name')"
          />
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "last_name") }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12">
          <label for="">Email*</label>
          <input
            maxlength="50"
            type="email"
            class="form-control"
            formControlName="email"
            [class.is-invalid]="fieldErrorMessage(registerForm, 'email')"
          />
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "email") }}
          </div>
        </div>
        <!-- <div class="form-group col-md-6">
          <label for="">Phone No.*</label>
          <input
            maxlength="13"
            appContactNumber
            type="tel"
            class="form-control"
            formControlName="contact"
            [class.is-invalid]="fieldErrorMessage(registerForm, 'contact')"
            placeholder="09XXXXXXXXX"
          />
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "contact") }}
          </div>
        </div> -->
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="">Mobile No.</label>
          <input
            maxlength="13"
            minlength="11"
            appContactNumber
            type="tel"
            class="form-control"
            formControlName="mobile"
            [class.is-invalid]="fieldErrorMessage(registerForm, 'mobile')"
            placeholder="09XXXXXXXXX"
          />
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "mobile") }}
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="">Landline No.</label>
          <input
            maxlength="10"
            minlength="9"
            appContactNumber
            type="tel"
            class="form-control"
            formControlName="landline"
            [class.is-invalid]="fieldErrorMessage(registerForm, 'landline')"
            placeholder="02XXXXXXXX"
          />
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "landline") }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group pass col-md-6">
          <label for="">Password*</label>
          <div class="input-password">
            <input
              maxlength="50"
              [type]="isShowPass.password ? 'text' : 'password'"
              class="form-control"
              formControlName="password"
              [class.is-invalid]="fieldErrorMessage(registerForm, 'password')"
            />
            <span
              [ngClass]="{
                'custom-icon-eye-regular': !isShowPass.password,
                'custom-icon-eye-slash': isShowPass.password
              }"
              class="icon"
              (click)="isShowPass.password = !isShowPass.password"
            ></span>
          </div>
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "password") }}
          </div>
        </div>
        <div class="form-group pass col-md-6">
          <label for="">Confirm Password*</label>
          <div class="input-password">
            <input
              maxlength="50"
              [type]="isShowPass.confirm_pass ? 'text' : 'password'"
              class="form-control"
              formControlName="password_confirmation"
              [class.is-invalid]="
                fieldErrorMessage(registerForm, 'password_confirmation')
              "
            />
            <span
              [ngClass]="{
                'custom-icon-eye-regular': !isShowPass.confirm_pass,
                'custom-icon-eye-slash': isShowPass.confirm_pass
              }"
              class="icon"
              (click)="isShowPass.confirm_pass = !isShowPass.confirm_pass"
            ></span>
          </div>
          <div class="invalid-feedback">
            {{ fieldErrorMessage(registerForm, "password_confirmation") }}
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="reCaptcha?.enabled">
        <re-captcha
          class="border-0"
          #recaptchaRef
          [(ngModel)]="isCaptchaValid"
          [ngModelOptions]="{ standalone: true }"
          (resolved)="captchaResolved($event)"
          [siteKey]="reCaptcha?.siteKey"
        >
        </re-captcha>
      </div>

      <div class="form-group" *ngIf="responseErrors?.length">
        <p *ngFor="let resErr of responseErrors">
          <ngb-alert type="danger" (close)="closeErrorAlert(resErr)">{{
            resErr
          }}</ngb-alert>
        </p>
      </div>

      <div class="form-group">
        <input
          [disabled]="
            !registerForm.valid || (reCaptcha?.enabled && !isCaptchaValid)
          "
          value="Register"
          class="btn buttonstyle-success w-100"
          type="submit"
        />
      </div>
    </form>

    <p>Already have an account? <a (click)="openSignInModal.emit()">Login</a></p>
  </div>
</div>
<!-- </div>
  </div>
</div> -->
