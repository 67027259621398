<div class="overflow-hidden border-radius-primary" style="width: 100%">
  <div class="product">
    <!-- Modal header start -->
    <div class="modal__header d-flex flex-column">
      <span class="custom-icon-delete ml-auto border-0" (click)="closeModal()"></span>
      <input class="product-title col-sm-6 ml-0" placeholder="Name of Set" [disabled]="!disableChangeName" (ngModelChange)="changeValueName($event)" [(ngModel)]="setInput"
              [ngModelOptions]="{standalone: true}">
      <!-- <h2 class="product-title ml-0">{{favoriteSet?.id}}</h2>       -->
      <div class="change-name">
        <span (click)="changeSetName()">
          {{!disableChangeName ? 'Toggle to change Set Name' : 'Changing set name'}}
        </span>
      </div>   
    </div>
    <!--Modal header End-->
    <!--Modal Body Start-->
    <div class="modal__body">
      <div class="container">
        <div class="apc-card">    
          <div class="apc-card-header" *ngIf="isAPCSet">
            <div style="color: #f45e04;">
              {{!apcCardCode ? '*This is an APC Set required APC Card, will reset cart' : 'APC Set'}}
            </div>
            <div class="remove-btn" *ngIf="!apcCardCode">
              <input type="text" digitOnly placeholder="APC #" [formControl]="apcCard">
              <button class="btn btn-primary w-15" (click)="saveApcCardNumber()">Use APC</button>
            </div> 
            <div class="remove-btn" *ngIf="apcCardCode">
              <input type="text" placeholder="{{apcCardCode}}" disabled="true">
              <button class="btn btn-danger w-15" [disabled]="addItemState" (click)="resetApcCardNumber()">Remove</button>
            </div>
          </div>                        
          <div class="apc-card-header" *ngIf="!isAPCSet">
            <div style="color: #f45e04;">
              {{!apcCardCode ? 'Non-APC Set' : '*This is an NON-APC Set remove APC Card, will reset cart' }}
            </div>
            <div class="remove-btn" *ngIf="apcCardCode">
              <input type="text" placeholder="{{apcCardCode}}" disabled="true">
              <button class="btn btn-danger w-15" [disabled]="addItemState" (click)="resetApcCardNumber()">Remove</button>
            </div>
          </div>          
        </div>
        <div class="favorite-card" *ngFor="let item of products; let i = index">
          <div class="border-btn display-on-mobile" [ngClass]="'delete'">
            <span class="custom-icon-delete ml-auto border-0" (click)="removeItem(i)"></span>
          </div>
          <div class="row favorite-summary">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="image">
                <img
                  [srcset]="(item?.media?.attributes ? item?.media?.attributes : item?.media) | image: 'src':'desktop'"
                  [alt]="(item?.media?.attributes ? item?.media?.attributes : item?.media) | image: 'alt'"
                  class="img-fluid w80-on-mobile"
                />
                <!-- <img src="assets/imgs/angels/menu-main-2.jpg" width="120px"
                    class="img-fluid w80-on-mobile" /> -->
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="favorite">                                          
                <div *ngIf="isAPCSet">
                  <div class="buy1take1" *ngIf="item.apc_card">
                    Buy 1 Take 1
                  </div>
                  <div class="favorite-name apc" *ngIf="item.apc_card">
                    {{item?.toggle == false ? item?.title : item?.apc_card?.product_take1?.title }}                                      
                  </div>
                  <div class="favorite-name non-mobile">
                    {{ item?.title }}
                    <div class="fav-name-variants" *ngIf="item.apc_card">
                      {{ " & " + item?.apc_card?.product_take1?.title }}
                    </div>    
                  </div>
                  <div class="availability" *ngIf="item?.is_available.length === 0">
                    (Unavailable)
                  </div>
                  <div class="favorite-price">
                    {{item?.price?.formatted || item?.selectedVariants[1]?.price?.formatted}}
                  </div>                 
                  <div class="favorite-items non-mobile" [ngClass]="{'d-none': !item?.selectedVariants.length}">                    
                    {{ item?.selectedVariants[0]?.title }}                     
                    {{ item?.selectedVariants[1]?.title }} 
                    <br>                                                     
                    {{ item?.apc_card?.product_take1?.variants[0]?.title }}                        
                    {{ item?.apc_card?.product_take1?.variants[1]?.title }}
                  </div>                
                  <div class="favorite-items is-mobile">                    
                    <label *ngIf="item?.toggle == false">
                      {{ item?.selectedVariants[0]?.title }}                     
                      {{ item?.selectedVariants[1]?.title }} 
                    </label>
                    <label *ngIf="item?.toggle == true">                                                       
                      {{ item?.apc_card?.product_take1?.variants[0]?.title }}                        
                      {{ item?.apc_card?.product_take1?.variants[1]?.title }}
                    </label>
                  </div>                 
  
                  <div class="addbutton">
                    <app-input-qty
                      [product]="item.selectedVariants[1] ? item.selectedVariants[1] : item"                      
                      [enableMaxQtyLimiter]="true"
                      [small]="true"
                      (inputChanged)="inputChanged($event, i)"                      
                    >
                    </app-input-qty>
                  </div> 
                  <div class="border-btn d-md-none d-lg-none" [ngClass]="{'more-info': isAPCSet, 'd-none': !isAPCSet}">
                    <span class="ml-auto border-0" 
                    [ngClass]="item.toggle ? 'custom-icon-arrow-left' : 'custom-icon-arrow-right'" 
                    (click)="showVariantsTake1(i)"></span>
                  </div>                 
                  <div class="remove hide-on-mobile" (click)="removeItem(i)">
                    Remove
                  </div>
                </div>
                <div *ngIf="!isAPCSet">
                  <div class="buy1take1" *ngIf="item?.apc_card_double_deal">
                    Double Deal
                  </div>
                  <div class="favorite-name apc">
                    {{item?.toggle == false ? item?.title : item?.apc_card?.product_take1?.title }}                                      
                  </div>
                  <div class="favorite-name non-mobile">
                    {{ item?.title }}
                    <div class="fav-name-variants" *ngIf="item?.apc_card_double_deal">
                      {{ " & " + item?.apc_card?.product_take1?.title }}
                    </div>                         
                  </div>
                  <div class="availability" *ngIf="item?.is_available.length === 0">
                    (Unavailable)
                  </div>
                  <div class="favorite-price">
                    {{item?.price?.formatted || item?.selectedVariants[1]?.price?.formatted}}
                  </div>
                  <div class="favorite-items non-mobile" [ngClass]="{'d-none': !item?.selectedVariants.length}">                    
                    {{ item?.selectedVariants[0]?.title }}
                    <br *ngIf="!item?.apc_card_double_deal">                     
                    {{ item?.selectedVariants[1]?.title }}
                    <br>                                                     
                    {{ item?.apc_card?.product_take1?.variants[0]?.title }}                        
                    {{ item?.apc_card?.product_take1?.variants[1]?.title }}  
                  </div>  
                  <div class="favorite-items is-mobile">                    
                    <label *ngIf="item?.toggle == false">
                      {{ item?.selectedVariants[0]?.title }}  
                      <br *ngIf="!item?.apc_card_double_deal">                   
                      {{ item?.selectedVariants[1]?.title }} 
                    </label>
                    <label *ngIf="item?.toggle == true">                                                       
                      {{ item?.apc_card?.product_take1?.variants[0]?.title }}                        
                      {{ item?.apc_card?.product_take1?.variants[1]?.title }}
                    </label>
                  </div>    
                  <div class="addbutton">
                    <app-input-qty
                      [product]="item.selectedVariants[1] ? item.selectedVariants[1] : item"
                      [enableMaxQtyLimiter]="true"
                      [small]="true"
                      (inputChanged)="inputChanged($event, i)"                      
                    >
                    </app-input-qty>
                  </div>
                  <div class="border-btn d-md-none d-lg-none" [ngClass]="{'more-info': item?.apc_card_double_deal, 'd-none': !item?.apc_card_double_deal}">
                    <span class="ml-auto border-0" 
                    [ngClass]="item.toggle ? 'custom-icon-arrow-left' : 'custom-icon-arrow-right'" 
                    (click)="showVariantsTake1(i)"></span>
                  </div>  
                  <div class="remove hide-on-mobile" (click)="removeItem(i)">
                    Remove
                  </div>
                </div>
                
              </div>
            </div>           
          </div>  
        </div>   
        
        
        <div class="add-item">
          <a (click)="close()" [class.disabled]="isAPCSet ? !apcCardCode : apcCardCode" >
            Add more items...
          </a>
        </div>
        
      </div>
    </div>
    <!--Modal Body End-->
    <!--Modal Footer Start-->
    <div class="modal__footer">
      <div class="container">
        <div class="d-block">   
          <div class="update-info" *ngIf="(!isAPCSet && apcCardCode) || (isAPCSet && !apcCardCode || addItemState)">
            Please update set before adding to cart
          </div>      
          <button class="btn btn-primary btn-cart w-100 justify-content-center" (click)="cartSeperator()" [disabled]="addItemState ? addItemState : (isAPCSet ? !apcCardCode : apcCardCode) || disableBtn"><img src="/assets/imgs/angels/your-cart.png" alt="image">Add to cart</button>
          <button class="btn btn-success w-100 justify-content-center" (click)="updateFavoriteSet() && modalRef.close(true)"[disabled]="!isAPCSet && apcCardCode || disableBtn">Update Changes</button>
          <button class="btn btn-danger w-100 justify-content-center" (click)="deleteSet() && modalRef.close(true)">Delete List</button>
        </div>
      </div>
    </div>
    <!--Modal Footer End-->
  </div>
</div>
