import { BranchLocationService } from "../../services/branch-location/branch-location.service";
import { PromoCodeModalComponent } from "../promo-code-modal/promo-code-modal.component";
import { AppService } from "../../services/app/app.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog/confirm-dialog.component";
import { InformationDialogComponent } from "../information-dialog/information-dialog.component";
import { SelectBranchComponent } from "../select-branch/select-branch.component";
import { LoaderService } from "../../services/loader.service";
import { WindowResizeService } from "src/app/services/window-resize.service";
import { Title } from "@angular/platform-browser";
import { ToastService } from "../../services/toast/toast.service";
import { ApiService } from "../../services/api/api.service";
import { StoreService } from "../../services/store/store.service";
import { CartService } from "../../services/cart/cart.service";
import { isPlatformBrowser, Location } from "@angular/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  HostListener,
} from "@angular/core";
import Swiper from "swiper";
import { LocationWidgetModalComponent } from "../location-widget-modal/location-widget-modal.component";
import { UnslugifyPipe } from "../../pipes/unslugify/unslugify.pipe";
@Component({
  selector: "app-menu-item-select",
  templateUrl: "./menu-item-select.component.html",
  styleUrls: ["./menu-item-select.component.scss"],
  providers: [UnslugifyPipe],
})
export class MenuItemSelectComponent implements OnInit {
  pageUrlKey = "";
  useVoucherCode = true; // auto use in true default value
  isFromSearch = false;
  searchProductByVoucherFn: any;

  modalCtrl: any;
  childModalState = false; // means another modal is not on current view
  productDetails: any;
  pageNumber: any;
  catSegment = "";

  products: any;

  variantLimitArr = [];
  disabledVariants = [];

  isCartEdit = false;
  productIndexFromCart = null;

  productSlider = {
    slidesPerView: 1,
    observeParents: true,
    observer: true,
    watchOverflow: true,
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    },
  };

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeModal();
  }

  constructor(
    private modalService: NgbModal,
    private cartService: CartService,
    private location: Location,
    private storeService: StoreService,
    private apiService: ApiService,
    private toastService: ToastService,
    private title: Title,
    public windowResizeService: WindowResizeService,
    private loaderService: LoaderService,
    private unslugify: UnslugifyPipe,
    private appService: AppService,
    public branchLocationService: BranchLocationService,
    @Inject(PLATFORM_ID) public platformId
  ) {}

  ngOnInit(): void {
    console.log(this.products);
    if (this.products && this.products.products.length === 1) {
      this.productDetails = this.products.products[0];
      console.log(this.productDetails);
      if (this.productDetails) {
        if (this.productDetails.extra.maximum_variant_selection) {
          this.variantLimitArr = Object.keys(
            this.productDetails.extra.maximum_variant_selection
          );
          console.log(this.variantLimitArr);
        }

        const keys = Object.keys(this.productDetails);
        if (keys.includes("disabled_variants")) {
          this.disabledVariants = this.productDetails.disabled_variants;
        }

        this.useVoucherCode = true;
        this.getProduct(this.productDetails.id);

        // this.setVoucherOnProductCart(); // temp rm
      }
    }
  }

  setProduct(product) {
    this.productDetails = JSON.parse(JSON.stringify(product));
    console.log(this.productDetails);
    if (this.productDetails) {
      if (this.productDetails.extra.maximum_variant_selection) {
        this.variantLimitArr = Object.keys(
          this.productDetails.extra.maximum_variant_selection
        );
        console.log("variant limit array => ", this.variantLimitArr);
      }


      let selectedVariants = {};

      if (this.productDetails.selectedVariants && this.productDetails.selectedVariants.length) {
        this.productDetails.selectedVariants = this.productDetails.selectedVariants.map(v => {
          // v.type = v.type.charAt(0).toUpperCase() + v.type.slice(1);
          if (this.variantLimitArr.includes(v.type.toLowerCase())) {
            if (!selectedVariants[v.type]) {
              selectedVariants[v.type] = 1
            } else {
              selectedVariants[v.type]++
            }
          }
          return v;
        })

        let selectedVariantKeys = Object.keys(selectedVariants);
        selectedVariantKeys.forEach(k => {
          console.log(this.productDetails.extra.maximum_variant_selection[k.toLowerCase()])
          if (selectedVariants[k] >= this.productDetails.extra.maximum_variant_selection[k.toLowerCase()].maximum_selection) {
            this.productDetails.variants[k][0].variants.map(v => {
              if (!v.selected) {
                v.disabled = true;
              }
              return v;
            })
          }
        });
        console.log(selectedVariants)
      }

      const keys = Object.keys(this.productDetails);
      if (keys.includes("disabled_variants")) {
        this.disabledVariants = this.productDetails.disabled_variants;
      }

      this.useVoucherCode = true;
      this.getProduct(this.productDetails.id);

      // this.setVoucherOnProductCart(); // temp rm
    }
  }

  async getProduct(hashedId) {
    // only use is pass record to backend as `seen` product
    try {
      const branch = await this.storeService.getItem("branch");
      let headers = {};

      headers = {
        "x-branch": branch || "all",
      };
      await this.apiService.request({
        method: "GET",
        url: `/products/${hashedId}`,
        headers,
        jsona: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  closeModal() {
    // this.modalService.dismissAll();
    this.modalCtrl.close();
    if (this.modalCtrl && !this.childModalState && !this.isCartEdit) {
      this.location.replaceState(
        `${this.pageUrlKey || "menu"}/${this.pageNumber}/${this.catSegment}`
      );
      let siteTitle = this.pageUrlKey === "use-my-coupon" ? "Promo" : "Menu";
      if (this.appService.siteName) {
        siteTitle = `${siteTitle} - ${this.appService.siteName}`;
      }
      this.title.setTitle(siteTitle);
    }
  }

  async showBranchList() {
    const branch = await this.storeService.getItem("branch");
    const branchLocation = await this.storeService.getItem("branchLocation");
    const deliveryType = await this.storeService.getItem("deliveryType");
    // if (!branchLocation || !branch) {
    //   return;
    // }
    // if ((branchLocation['city'] && deliveryType === 'delivery') || (branchLocation['selected_branch'] && deliveryType === 'pick-up')) {
    //   return;
    // }

    const user = await this.storeService.getItem("user");
    const modalComponent = this.modalService.open(
      LocationWidgetModalComponent,
      { windowClass: "location-widget-modal", size: "lg", centered: true }
    );
    modalComponent.componentInstance.user = user;
    modalComponent.componentInstance.isOnMenu = true;
    modalComponent.componentInstance.modalCtrl = modalComponent;
    this.childModalState = true;
    modalComponent.result.then((value) => {
      if (value) {
        console.log(value);
        this.childModalState = value.modalState;
      }
    });
  }

  async setVoucherOnProductCart() {
    const product = JSON.parse(JSON.stringify(this.productDetails)); // deep copy;
    const productOnCart: any = await this.cartService.voucherCodeInCart(
      product
    );

    if (!Array.isArray(productOnCart)) {
      return;
    }
    if (!productOnCart.length) {
      return;
    }
    const cartItem = productOnCart[0];
    if (!cartItem.voucher_code) {
      return;
    }

    this.productDetails.voucher_code = cartItem.voucher_code;
    this.useVoucherCode = true;
  }

  async showVoucherModal(cartItemAddedSuccessText) {
    const modalComponent = this.modalService.open(PromoCodeModalComponent, {
      windowClass: "location-widget-modal",
      size: "lg",
      centered: true,
    });
    modalComponent.componentInstance.modalCtrl = modalComponent;
    modalComponent.componentInstance.cartItemAddedSuccessText = cartItemAddedSuccessText;
    modalComponent.componentInstance.searchPromoCodeFn = this.searchProductByVoucherFn.bind(
      this
    );
  }

  async addToCart(productItem) {
    const product = JSON.parse(JSON.stringify(productItem)); // deep copy;

    if (!this.useVoucherCode) {
      product.voucher_code = "";
    }

    const branch = await this.storeService.getItem("branch");
    const branchLocation: any = await this.storeService.getItem(
      "branchLocation"
    );
    const deliveryType: any = await this.storeService.getItem("deliveryType");
    if (!branchLocation || !branch) {
      this.showBranchList();
      return;
    }
    if (
      (!branchLocation["city"] && deliveryType === "delivery") ||
      (!branchLocation["selected_branch"] && deliveryType === "pick-up")
    ) {
      this.showBranchList();
      return;
    }

    // const productOnCart: any = await this.cartService.itemInCart(product);
    const productOnCart: any = await this.cartService.isInCart(product, "item");
    const productOnCartQuantity = productOnCart ? productOnCart.quantity : 0;
    console.log(productOnCartQuantity);
    if (product.quantity + productOnCartQuantity > 50) {
      this.toastService.showDangerToast(
        `${product.title} has a maximum quantity of 50`
      );
      // product.quantity = (50 - productOnCartQuantity);
      return;
    }

    if (
      (product.quantity + productOnCartQuantity < product.minimum_quantity &&
        !(await this.cartService.isInCart(product))) ||
      product.quantity === 0 ||
      !product.quantity
    ) {
      this.toastService.showDangerToast(
        `${product.title} has a minimum quantity of ${product.minimum_quantity}`
      );
      return;
    }

    const token = await this.storeService.getItem("token");
    const brgy =
      deliveryType === "delivery"
        ? await this.storeService.getItem("brgy_segment")
        : null;

    if (!Array.isArray(product.variants)) {
      // {} formatted variants, [] empty variants

      if (product.variants.length && product.selectedVariants.length <= 0) {
        this.showDangerToast(`Please choose a variant first.`);
        return;
      }

      const missingSelectedVariants = this.isAllVariantsSelected(
        product.selectedVariants
      );
      if (missingSelectedVariants.length) {
        missingSelectedVariants.filter((type) => {
          this.showDangerToast(
            `${this.unslugify.transform(type)} is required.`
          );
        });
        return;
      }

      let error = false;
      product.selectedVariants.forEach(v => {
        if (v.quantity < 1) {
          this.showDangerToast(`${v.title} has a minimum quantity of 1`);
          error = true;
        } else if (v.quantity > 50) {
          this.showDangerToast(`${v.title} has a maximum quantity of 50`);
          error = true;
        }
      });
      if (error) return;
    }

    // const itemInCart: any = await this.cartService.itemInCart(product);
    const itemInCart: any = await this.cartService.isInCart(product, "item");
    let itemQuantityInCart = 0;
    if (itemInCart) {
      itemQuantityInCart = itemInCart.quantity;
    }

    if (!token) {
      try {
        const cartClone = await this.cartService.preCartValidationTransform(
          product
        );
        console.log("cart clone", cartClone);
        await this.cartService.guestCartValidationChecker(brgy, cartClone);
      } catch (err) {
        const hasMessageKey = Object.keys(err.error).includes("message");
        if (hasMessageKey) {
          if (err.error.message.includes("Header X-Branch value")) {
            await this.resetBranchLocation();
            return;
          }
        }

        // this.cartService.customErrMsgCheckTotalGuest(err);
        if (err.status === 401) {
          return;
        }
        this.apiService.showErrors(err);
        return;
      }
    }

    // if (!token) {
    try {
      const hasStock: any = await this.cartService.checkProductStock({
        ...product,
        quantity: itemQuantityInCart + product.quantity,
      });
      if (hasStock.message === "has-stock") {
        if (!token) {
          // api validation checker (guest)
          await this.cartService.setCartData(product);

          const successMessage = `(${product.quantity}) ${product.title} successfully added to cart.`;
          this.toastService.showSuccessToast(successMessage);

          if (product.voucher_code) {
            this.modalCtrl.dismiss();
            this.showVoucherModal(successMessage);
          } else if (product.voucher_code && this.products.length > 1) {
            this.setProduct(null);
          }

          return;
        }
      }
      if (hasStock.message === "no-stock") {
        this.toastService.showDangerToast(
          `Sorry, ${product.title} is out of stock.`
        );
        return;
      }
    } catch (err) {
      console.log("err", err);
      this.loaderService.hideAsyncLoader();

      if (err.error.message === "stock not found" && err.status === 404) {
        this.toastService.showDangerToast(
          `Sorry, ${product.title} is out of stock.`
        );
      }

      const hasMessageKey = Object.keys(err.error).includes("message");
      if (hasMessageKey) {
        if (err.error.message.includes("Header X-Branch value")) {
          await this.resetBranchLocation();
          return;
        }
      }

      return;
    }
    // }
    this.loaderService.showAsyncLoader();

    let headers = {};

    headers = {
      "x-branch": branch || "all",
    };

    headers = { ...headers, Authorization: `Bearer ${token}` };

    try {
      const additionalProductPayload = product.selectedAdditionalProducts.map(
        (addon) => addon.id
      );
      const variantsPayload = product.selectedVariants.map((variant) => {
        return { id: variant.segment, quantity: variant.quantity };
      });
      console.log(variantsPayload);
      const voucherCodes: any =
        (await this.storeService.getItem("voucher_codes")) || [];
      const reqBody: any = {
        product: product.id,
        quantity: product.quantity,
        dietary_requirements: product.extra.dietary_requirements,
        sub_category: product.selected_sub_category,
        voucher_codes: voucherCodes,
        barangay: brgy,
      };

      if (variantsPayload.length) {
        reqBody.variants = variantsPayload;
      }
      if (additionalProductPayload.length) {
        reqBody.additional_products = additionalProductPayload;
      }
      if (product.voucher_code) {
        reqBody.voucher_code = product.voucher_code;
      }

      const res = await this.apiService.postRequest({
        url: "/auth/cart",
        body: reqBody,
        headers,
      });
      this.cartService.setCartStorage(res);
      // this.showSuccessToast(product.title);
      const successMessage = `(${product.quantity}) ${product.title} successfully added to cart.`;
      this.toastService.showSuccessToast(successMessage);
      this.loaderService.hideAsyncLoader();

      if (product.voucher_code) {
        this.modalCtrl.dismiss();
        this.showVoucherModal(successMessage);
      } else if (product.voucher_code && this.products.length > 1) {
        this.setProduct(null);
      }
    } catch (err) {
      this.loaderService.hideAsyncLoader();
      this.apiService.showErrors(err);
      // console.log(err);
      // const hasMessageKey = Object.keys(err.error).includes('message');
      // if (hasMessageKey) {
      //   this.toastService.showDangerToast(err.error.message);
      //   return;
      // }
      // const errorFields = Object.keys(err.error.errors);
      // errorFields.filter(key => {
      //   err.error.errors[key].filter(errMessage => {
      //     this.showDangerToast(errMessage);
      //   });
      // });
      // console.log(err);
    }
  }

  isAllVariantsSelected(selectedVariants) {
    // returns missing variant types[] ()
    const variantTypes = Object.keys(this.productDetails.variants);
    if (!selectedVariants.length) {
      return variantTypes;
    }
    const missingVariants = [];
    variantTypes.map((type) => {
      const filteredSelectedType = selectedVariants.filter(
        
        (selectedVariant) => {
          console.log(selectedVariant.type, type)
          return selectedVariant.type.toLowerCase().replace(' ', '-') === type.toLowerCase().replace(' ', '-')
        }
      );
      if (!filteredSelectedType.length) {
        missingVariants.push(type);
      }
    });
    return missingVariants;
  }

  showSuccessToast(productName) {
    this.toastService.show(`${productName} successfully added to cart.`, {
      classname: "bg-success text-light",
    });
  }

  showDangerToast(errorMessage) {
    this.toastService.show(errorMessage, { classname: "bg-danger text-light" });
  }

  setSubCategory(subCat) {
    this.productDetails.selected_sub_category = subCat;
  }

  // add or remove add-on (selected data are stored in `selectedAdditionalProducts`)
  setAdditionalProduct(addon) {
    let addonIndex = null;
    const hasAddOn = this.productDetails.selectedAdditionalProducts.filter(
      (selectedAddon, i) => {
        if (
          selectedAddon.id === addon.id &&
          selectedAddon.type === addon.type
        ) {
          addonIndex = i;
          return selectedAddon;
        }
      }
    );
    if (!hasAddOn.length) {
      addon.selected = true;
      this.productDetails.selectedAdditionalProducts.push(addon);
      // console.log('add', this.productDetails.selectedAdditionalProducts);
      return;
    }
    addon.selected = false;
    this.productDetails.selectedAdditionalProducts.splice(addonIndex, 1);
    // console.log('remove', this.productDetails.selectedAdditionalProducts);
  }

  // add or remove variations (selected data are stored in `selectedVariations`)
  setVariant(variation, isRadio?) {
    console.log("variation => ", variation);

    // for radio button that allows one selection only
    if (isRadio) {
      let variationIndexRadio = null;
      const hasSameType = this.productDetails.selectedVariants.filter(
        (selectedVariats, i) => {
          console.log(selectedVariats.type, variation.type)
          if (selectedVariats.type.toLowerCase() === variation.type.toLowerCase()) {
            variationIndexRadio = i;
            return selectedVariats;
          }
        }
      );
      if (hasSameType.length) {
        let keyTypes = this.productDetails.variants;
        for (let i = 0; hasSameType.length > i; i++) {
          console.log(hasSameType[i].type.charAt(0).toUpperCase() + hasSameType[i].type.slice(1));
          for (let j = 0; this.productDetails.variants[hasSameType[i].type].length > j; j++) {
            let variants = this.productDetails.variants[hasSameType[i].type][j].variants;
            console.log('variants => ', variants)
            if (variants && variants.length) {
              variants.map(v => {
                console.log(hasSameType[i].segment.toLowerCase().replaceAll(' ', '-'), v.segment.toLowerCase().replaceAll(' ', '-'))
                if (hasSameType[i].segment.toLowerCase().replaceAll(' ', '-') === v.segment.toLowerCase().replaceAll(' ', '-')) {
                  console.log('same type')
                  // return {...v, selected: false};
                  v.selected = false;
                }
                return v;
              })
            }
            this.productDetails.variants[hasSameType[i].type][j].variants = variants;
          }
        }
        this.productDetails.selectedVariants.splice(variationIndexRadio, 1);
        console.log(this.productDetails)
      }
      variation.selected = true;
      variation.quantity = 1;
      this.productDetails.selectedVariants.push(variation);
      // console.log(this.productDetails.selectedVariants);
      // console.log(variation);
      return;
    }

    let variationIndex = null;
    const hasVariation = this.productDetails.selectedVariants.filter(
      (selectedVariants, i) => {
        if (
          selectedVariants.segment === variation.segment &&
          selectedVariants.type === variation.type
        ) {
          variationIndex = i;
          return selectedVariants;
        }
      }
    );

    if (hasVariation.length) {
      variation.selected = false;
      this.productDetails.selectedVariants.splice(variationIndex, 1);
      // console.log('remove', this.productDetails.selectedVariants);
    }

    if (!hasVariation.length) {
      variation.selected = true;
      variation.quantity = 1;
      this.productDetails.selectedVariants.push(variation);
      // console.log('add', this.productDetails.selectedVariants);
    }

    // variant limit checker
    const typeHasLimit = this.variantLimitArr.filter((variationLimitType) => {
      if (variationLimitType.toLowerCase() === variation.type.toLowerCase()) {
        console.log("has limit");
        return true;
      }
      return false;
    });
    // console.log('type limit', typeHasLimit, this.variantLimitArr, variation.type);
    console.log('has limits => ', typeHasLimit);
    if (typeHasLimit.length) {
      const selectedItemsByTypes = this.itemsWithSameType(variation);
      const typeLimitList = this.productDetails.extra.maximum_variant_selection;

      console.log(selectedItemsByTypes);
      console.log(variation.type.toLowerCase());
      console.log(
        parseInt(
          typeLimitList[variation.type.toLowerCase()].maximum_selection,
          10
        )
      );
      console.log("variants => ", this.productDetails.disabled_variants);
      // disable variants on limit
      if (
        selectedItemsByTypes.length >=
        parseInt(
          typeLimitList[variation.type.toLowerCase()].maximum_selection,
          10
        )
      ) {
        this.changeDisabledState("variants", variation.type, true);
        this.disabledVariants.push(variation.type);
        this.productDetails.disabled_variants.push(variation.type);
        console.log("disabled...");
      }
      console.log("variants => ", this.productDetails.disabled_variants);

      // enable disabled variants if checked < limit
      const disabledVariant = this.disabledVariants.filter(
        (disabledVariantType) => disabledVariantType === variation.type
      );
      // console.log('disabledVariant type', disabledVariant);
      if (
        selectedItemsByTypes.length <
          parseInt(
            typeLimitList[variation.type.toLowerCase()].maximum_selection,
            10
          ) 
        //   &&
        // disabledVariant.includes(variation.type)
      ) {
        this.changeDisabledState("variants", variation.type, false);
        const variantIndex = this.disabledVariants.indexOf(variation.type);
        const productVariantIndex = this.productDetails.disabled_variants.indexOf(
          variation.type
        );
        this.disabledVariants.splice(variantIndex, 1);
        this.productDetails.disabled_variants.splice(productVariantIndex, 1);
        console.log('enable variants');
      }
    }

    return variation;
  }

  changeDisabledState(type, subType, state) {
    this.productDetails[type][subType].map((productAddon) => {
      if (!productAddon.selected) {
        productAddon.disabled = state;
      }
      if (type === "variants") {
        if (productAddon.variants.length) {
          productAddon.variants.map((subVariant) => {
            if (!subVariant.selected) {
              subVariant.disabled = state;
              console.log("isDisabled", subVariant.disabled);
            }
          });
        }
      }
    });
  }

  itemsWithSameType(item) {
    return this.productDetails.selectedVariants.filter((selectedVariats) => {
      if (selectedVariats.type.toLowerCase() === item.type.toLowerCase()) {
        console.log("equal");
        return selectedVariats;
      }
    });
  }

  showInfoModal(title, message) {
    const modalCtrl = this.modalService.open(InformationDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "postcode-confirmation-modal",
      size: "lg",
      centered: true,
    });
    modalCtrl.componentInstance.message = message;
    modalCtrl.componentInstance.title = title;
  }

  async removeItem(product, index) {
    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "confirm-dialog-modal",
      centered: true,
    });

    modalComponent.componentInstance.title = "Remove Item to Cart";
    modalComponent.componentInstance.confirmationMessage =
      "Are you sure you want to remove this item to cart?";
    modalComponent.result.then(async (res) => {
      if (res) {
        const token = await this.storeService.getItem("token");
        if (!token) {
          this.cartService.removeItemInCart(product, index);
        } else {
          const cartItem = this.cartService.cartStorage[
            this.productIndexFromCart
          ];
          this.loaderService.showAsyncLoader();
          await this.cartService.removeCartItem(cartItem);
          this.loaderService.hideAsyncLoader();
        }
        this.modalCtrl.close();
      }
    });
  }

  async updateItemInCart(product, index) {
    const token = await this.storeService.getItem("token");

    // validations
    if (!this.useVoucherCode) {
      product.voucher_code = "";
    }

    const branch = await this.storeService.getItem("branch");
    const branchLocation: any = await this.storeService.getItem(
      "branchLocation"
    );
    const deliveryType: any = await this.storeService.getItem("deliveryType");
    if (!branchLocation || !branch) {
      this.showBranchList();
      return;
    }
    if (
      (!branchLocation["city"] && deliveryType === "delivery") ||
      (!branchLocation["selected_branch"] && deliveryType === "pick-up")
    ) {
      this.showBranchList();
      return;
    }

    if (product.quantity > 50) {
      this.toastService.showDangerToast(
        `${product.title} has a maximum quantity of 50`
      );
      product.quantity = 50;
      return;
    }

    if (
      product.quantity < product.minimum_quantity ||
      product.quantity === 0 ||
      !product.quantity
    ) {
      this.toastService.showDangerToast(
        `${product.title} has a minimum quantity of ${product.minimum_quantity}`
      );
      product.quantity = product.minimum_quantity;
      return;
    }

    if (!Array.isArray(product.variants)) {
      // {} formatted variants, [] empty variants

      if (product.variants.length && product.selectedVariants.length <= 0) {
        this.showDangerToast(`Please choose a variant first.`);
        return;
      }

      console.log('product => ', product.selectedVariants);

      const missingSelectedVariants = this.isAllVariantsSelected(
        product.selectedVariants
      );
      console.log(missingSelectedVariants);
      if (missingSelectedVariants.length) {
        missingSelectedVariants.filter((type) => {
          this.showDangerToast(
            `${this.unslugify.transform(type)} is required.`
          );
        });
        return;
      }

      let error = false;
      product.selectedVariants.forEach(v => {
        if (v.quantity < 1) {
          this.showDangerToast(`${v.title} has a minimum quantity of 1`);
          error = true;
        } else if (v.quantity > 50) {
          this.showDangerToast(`${v.title} has a maximum quantity of 50`);
          error = true;
        }
      });
      if (error) return;

    }

    this.loaderService.showAsyncLoader();
    if (!token) {
      this.cartService.updateGuestCart(product, index);
      this.toastService.showSuccessToast(`Cart successfully updated.`);
      this.loaderService.hideAsyncLoader();
      return;
    }
    const additionalProductPayload = product.selectedAdditionalProducts.map(
      (addon) => addon.id
    );
    const variantsPayload = product.selectedVariants.map(
      (variant) => {
        return { id: variant.segment, quantity: variant.quantity };
      }
    );
    const cartItem = this.cartService.cartStorage[this.productIndexFromCart];
    const payload = {
      id: cartItem.id,
      quantity: product.quantity,
      dietary_requirements: product.extra.dietary_requirements,
      sub_category: product.selected_sub_category,
      additional_products: additionalProductPayload,
      variants: variantsPayload,
    };
    try {
      await this.cartService.updateCartItem(payload);
      this.toastService.showSuccessToast(`Cart successfully updated.`);
    } catch (err) {
      this.toastService.showDangerToast("Failed to update cart");
      console.log(err);
    } finally {
      this.loaderService.hideAsyncLoader();
    }
  }

  async resetBranchLocation() {
    await this.storeService.removeItem("branch");
    await this.storeService.removeItem("branchLocation");
    await this.storeService.removeItem("deliveryType");
    await this.cartService.emptyCart(true);
    this.showBranchList();
  }

  inputChanged(value) {
    console.log(this.productDetails)
    this.productDetails.selectedVariants = this.productDetails.selectedVariants.map(v => {
      if (v.segment === value.segment) {
        return value;
      }
      return v;
    });
  }
}
