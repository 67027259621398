<section class="block-terms page-content py-2">
    <div class="close-btn">
        <span class="custom-icon-delete ml-auto border-0" (click)="closeModal()"></span>
    </div>
    <div class="container" [innerHTML]="apiService?.agreementsContent?.terms_condition">
        <!-- <h2>Terms And Conditions</h2>

        <p>These terms and conditions outline the rules and regulations for the use of VIVA International Food &
            Restaurants, Inc. Website.</p>

        <p>VIVA International Food & Restaurants, Inc. is located at:</p>
        <p>Unit E905, 9th Floor, East Tower, Philippine Stock Exchange Centre,</p>
        <p>Exchange Road, Ortigas, Pasig City, Philippines</p>

        <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
            VIVA International Food & Restaurants, Inc. website if you do not accept all of the terms and conditions
            stated on this page.</p>

        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and
            any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and
            accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to
            our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or
            ourselves. All terms refer to the offer, acceptance and consideration of information necessary to undertake
            the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a
            fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of
            Philippines. Any use of the above terminology or other words in the singular, plural, capitalization and/or
            he/she or they, are taken as interchangeable and therefore as referring to same.</p>

        <h2>Social Media Terms & Conditions</h2>
        <p>The images, posts, conversations, boards and hashtags created or administered by "company name" across any
            social media platform, including but not limited to, facebook, instagram, twitter, pinterest and linkedin,
            are for our fans, followers, and retailers entertainment. We welcome posts, pictures and videos on our
            social handles as long as they are respectful and in correspondence with the terms & conditions of each
            platform’s terms of use and privacy policy.</p>

        <p>All users agree to the following terms: (1) Shares, posts, and content may or may not be accurate, complete,
            reliable or up to date; (2) You read and follow our social handles at your own risk; (3) Any content posted
            on our platforms by fans will not violate any law or regulation including, without limitation, copyright,
            trademark, privacy or publicity rights; (4) Any content we are tagged in or posted on our platforms will not
            contain disrespectful, obscene or profane language.</p>

        <p>You are responsible for all content posted to our social handles, and "Company Name" will not take
            responsibility for you. "Company Name" has the sole discretion and right to review and remove any content
            for any reason we see fit. All content that violates against us or our Terms and Conditions will be removed.
            For any pictures, videos and/or posts shared with us or on our social handles are granting "Company Name"
            the permission to use, copy, modify, adapt, publish and repost such content on our own social media handles.
            Do not post or share any content that you do not wish for "Company Name" to re-share. Additionally, you
            allow "Company Name" to “tag” the username and/or the handle associated with the post.</p>

        <p>Additionally, specific areas on our website may allow you to share or repost Content on your personal social
            media handles. You are permitted a limited license to display the Content as directed. You have no other
            title to the Content posted by VIVA International Food & Restaurants, Inc.</p>



        <h2>VIFRI WEBSITE AND MOBILE APP TERMS OF USE:</h2>

        <p>By entering VIVA International Food & Restaurants, Inc.’s website and mobile app, you agree to the terms and
            conditions of use as governed its owner and operator, VIVA International Food & Restaurants, Inc. (referred
            to as “VIFRI”). VIFRI uses the Internet to collect and process your personal information as listed below,
            that may be used and transmitted in our outside of the Philippines.</p>

        <p> • Salutation</p>
        <p> • Full Name</p>
        <p> • Address Type (Home / Work / Other Address)</p>
        <p> • Delivery Address</p>
        <p> • Email Address</p>
        <p> • Contact Number (Home / Work / Other Contact Number)</p>
        <p> • Birthday</p>
        <p> • Social Networking or Online Messaging Accounts</p>

        <p>The data you share will be used in creating your account in VIFRI’s website or mobile app, delivery order
            processing and marketing communication content and offers. The account may only be accessed by the user and
            is ensured of the highest security.</p>

        <p>You are entirely responsible for maintaining the confidentiality of your password and your account. And you
            are entirely responsible for all activity made by you or anyone that uses your account. You agree to
            safeguard your password from access by others. If you believe that your account has been compromised, you
            must immediately contact us by mail at: <strong>(insert email here)</strong>. You agree to indemnify and hold harmless VIFRI
            for losses incurred including those incurred by another party due to someone else using your account as a
            result of your failure to use reasonable care to safeguard your password.</p>

        <p>The personal data you have submitted is used only for business purposes within VIFRI which may include it’s
            communication to our affiliates and subsidiaries.</p>

        <h2>ONLINE ORDERS</h2>

        <h2>Placement of Orders</h2>
        <p>Any order that you placed from this website to purchase a particular product for the stated price specified
            in this website at the time you place your order. Delivery time may vary for orders made above P 5,000.</p>

        <h2>Acceptance of Order</h2>
        <p>We reserve the right to accept or reject your order for any reason, such as the unavailability of any
            product, an error in the price of the product or an error in your order. As there may be reasons why we
            cannot fulfill your order, your contract with us will only come into existence when we advise you that your
            payment has been confirmed.</p>

        <h2>Pricing</h2>
        <p>All prices are VAT inclusive.</p>
        <p>Prices of goods and other charges displayed on the “Botejyu, Wing Zone & Paper Moon” websites are current at
            the time of display. These prices are subject to variation without notice. We will always attempt to be as
            accurate as possible with our product descriptions and pricing. However, we are unable to warrant that
            product descriptions or other content of the website is accurate, complete, reliable, current or error free.
            Prices are subject to change without prior notice.</p>



        <h2>Delivery</h2>
        <p>Food delivery time will depend upon the availability of products in our stores, nature of food preparation
            and customer distance from the nearest Botejyu, Wing Zone & Paper Moon stores, or as agreed with our
            customer service representative. Delivery services may be temporarily suspended in the event of calamities,
            extreme weather conditions, and other events that might affect the safety of our delivery personnel.</p>

        <h2>Payments</h2>
        <p>Other form of payments may include cash, credit/debit card and gift-certificates..</p>

        <h2>Sub-total Price</h2>
        <p>The prices displayed will be the total purchase price.</p>

        <h2>Cancellation by Us</h2>
        <p>We reserve the right not to provide products, remove or edit content or cancel orders (or part thereof) at
            our sole discretion at any time. Without limiting the operation of any other Terms and Conditions herein, we
            will not be held liable for loss or damage arising from the exercising of these rights.</p>

        <h2>Cancellation by You</h2>
        <p>Due to the perishable nature of the products and made to order process, “VIVA International Food &
            Restaurants, Inc.” will not accept any cancelled orders once the payment is confirmed. If you would like to
            discuss a cancellation please contact us at <strong>(insert email here)</strong> quoting your Name, Order Reference Number
            and the date of your order.</p>

        <h2>Refund</h2>
        <p>No refund shall be issued upon confirmation of payment.</p>

        <h2>Changes to This Terms and Conditions</h2>
        <p>We may update our Terms and Conditions from time to time. Thus, we advise you to review this page
            periodically for any changes. We will notify you of any changes by posting a pop-up window upon entry to our
            Landing Page. These changes are effective immediately, after they are posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions, suggestions or complaints about our Terms and Conditions, do not hesitate to
            contact us.</p>

        <p>For operational related questions or suggestions/recommendations:</p>
        <p><strong>(insert email here)</strong></p>   

        <p>For Terms and Conditions concerns or complaints:</p>
        <strong>dpovivacommunications@viva.com.ph or at +63 2 687 6853 local 789.</strong> -->
    </div>
    <div class="agree-btn">
        <button class="btn" (click)="closeModal()">I Agree</button>
    </div>
</section>