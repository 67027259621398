<!-- modal header start -->
<div class="modal__header">
    <span class="custom-icon-delete" (click)="closeModal()"></span>
</div>
  <!-- modal header end -->
  
  <!-- modal body start -->
  <div class="modal__body">
    
    <!-- <div class="col-12">
        <div class="promo-found">
            <h3 class="mb-0 text-success">{{cartItemAddedSuccessText}}</h3>
        </div>
    </div> -->

    
    <div class="col-12">
        <div class="promo-code align-items-baseline justify-content-center">
            <h3 class="mr-3">Do you have another promo code?</h3>
            <!-- <h3 class="promo-label cabin-b m-0">Enter Promo Code</h3> -->
            <div class="d-flex flex-column">
                <div class="promo-input mr-0">
                    <input type="text" class="form-control" [(ngModel)]="promoCode" (keydown.enter)="searchProductByPromo(promoCode)">
                    <button class="btn btn-primary" (click)="searchProductByPromo(promoCode)">
                    <span>Enter</span>
                    </button>
                </div>
                <!-- if incorrect code -->
                <h3 class="text-secondary m-0 mt-2 font-italic" *ngIf="!isPromoCodeValid" [innerHTML]="promoCodeErrMessage"></h3>    
            </div>
        </div>

        <div class="action-buttons">
            <span class="mx-2"> -or- </span>

            <button class="btn btn-primary ml-3" (click)="gotoMenu()">Order More</button>

            <button class="btn btn-primary ml-3" (click)="gotoCheckout()">Proceed to Checkout</button>    
        </div>
        
    </div>
    
  </div>
