import { isPlatformBrowser } from '@angular/common';
import { ToastService } from './../../services/toast/toast.service';
import { CartService } from './../../services/cart/cart.service';
import { FormValidationService } from './../../services/validation/form-validation.service';
import { LoaderService } from './../../services/loader.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from './../../services/store/store.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-select-branch',
  templateUrl: './select-branch.component.html',
  styleUrls: ['./select-branch.component.scss']
})
export class SelectBranchComponent implements OnInit {
  // branches: any;
  // branchProvinces: any;
  selectedBranch: any;
  branchForm: FormGroup;
  fullAddress = '';
  user: any;
  disableDropdownButtons = false;
  savedSuccess = false;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.modalService.dismissAll();
  }
  constructor(
    private apiService: ApiService,
    private storeService: StoreService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private cartService: CartService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId
  ) { }

  ngOnInit() {
    console.log('select branch user log', this.user);
    this.branchForm = this.formBuilder.group({
      province: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      city: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      brgy: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      selected_branch: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      // fullAddress: ['', Validators.compose([Validators.required, Validators.maxLength(50), FormValidationService.NoWhitespaceValidator])]
    });

    // this.getBranches();
    this.setChosenBranchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getProvinces();
    }
  }

  // async getBranches() {
  //   try {
  //     const branchList = await this.apiService.request({
  //       method: 'GET',
  //       url: '/branches'
  //     });
  //     const sortedBranch = branchList.data.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
  //     this.branches = sortedBranch;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async setChosenBranchData() {
    if (this.fullAddress && !this.user) {
      this.branchForm.get('fullAddress').setValue(this.fullAddress);
      this.branchForm.get('fullAddress').updateValueAndValidity();
    } else if (this.user) {
      if (this.user.addresses.length) {
        const userAddress = this.user.addresses[0].address_1;
        this.branchForm.get('fullAddress').setValue(userAddress);
      }
    }

    const branchLocation: any = await this.storeService.getItem('branchLocation');
    if (!branchLocation) {
      return;
    }
    this.disableDropdownButtons = true;
    this.branchForm.setValue({
      ...branchLocation
    });
    this.branchForm.updateValueAndValidity();

  }

  async getProvinces() {
    try {
      // this.loaderService.showAsyncLoader();
      const provinces = await this.apiService.request({
        method: 'GET',
        url: '/provinces',
        jsona: true
      });
      this.branchForm.get('province').setValue(provinces);
      this.branchForm.get('province').updateValueAndValidity();
      // this.loaderService.hideAsyncLoader();
      console.log(provinces);
    } catch (err) {
      // this.loaderService.hideAsyncLoader();
      this.toastService.showDangerToast('Failed to load locations, Please try again.');
      this.modalService.dismissAll();
      console.log(err);
    }
  }

  async setBranch(branchObj, type) {
    if (this.branchForm.get(type).value) {
      let keys = [];
      switch (type) {
        case 'province':
          keys = ['city', 'brgy', 'branch', 'selected_branch'];
          break;
        case 'city':
          keys = ['brgy', 'branch', 'selected_branch'];
          break;
        case 'brgy':
          keys = ['branch', 'selected_branch'];
          break;
        case 'branch':
          keys = ['selected_branch'];
          break;
        default:
          break;
      }
      keys.map(key => {
        this.branchForm.get(key).setValue(null);
        this.branchForm.get(key).updateValueAndValidity();
      });
    }

    this.branchForm.get(type).setValue(branchObj);
    this.branchForm.get(type).updateValueAndValidity();

    if (type === 'branch') { // temporary fix only
      const firstBranch = branchObj.branches[0];
      this.branchForm.get('selected_branch').setValue(firstBranch);
      this.branchForm.get('selected_branch').updateValueAndValidity();
    }
  }

  async setSelectedBranch() {
    if (!this.branchForm.valid) {
      return;
    }
    try {
      const selectedBranch = this.branchForm.get('selected_branch').value;
      const user = await this.storeService.getItem('user');
      await this.storeService.setItem('branchLocation', this.branchForm.value);
      await this.storeService.setItem('brgy_segment', this.branchForm.value.branch.segment);
      this.loaderService.showAsyncLoader();

      const storedBranchSegment = await this.storeService.getItem('branch');
      if (storedBranchSegment !== selectedBranch.segment) {
        if (Array.isArray(this.cartService.cartStorage)) {
          if (this.cartService.cartStorage.length) {
            if (user) {
              await this.cartService.removeAllCartItems();
            } else {
              await this.cartService.emptyCart();
            }
          }
        }

      }

      await this.storeService.setItem('branch', selectedBranch.segment);
      await this.storeService.removeItem('checkout_details');
      this.savedSuccess = true;
      setTimeout(() => {
        // this.modalService.dismissAll();
        this.loaderService.hideAsyncLoader();
        window.location.reload();
      }, 500);
    } catch (err) {
      this.loaderService.hideAsyncLoader();
      this.toastService.showDangerToast('Failed to change branch, Please try again.');
      console.log(err);
    }
  }

  removeDisabledState() {
    this.disableDropdownButtons = false;
  }

  closeModal() {
    console.log('close');
    this.modalService.dismissAll();
  }

}
