import { ApiService } from 'src/app/services/api/api.service';
import { LoaderService } from './../../services/loader.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor(
    private loaderService: LoaderService,
    public apiService: ApiService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.loadAgreements();
  }

  async loadAgreements() {
    await this.apiService.getAgreements();
    this.loaderService.hide();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
