<div class="overflow-hidden border-radius-primary" style="width: 100%">
  <div
    class="product"
    [class.product-oos]="
      !productDetails?.is_available[branchLocationService.branch] &&
      branchLocationService.branchLocation
    "
  >
    <!-- modal header start -->
    <div class="modal__header">
      <h2 class="product-title d-md-none">{{ productDetails?.title }}</h2>
      <span class="custom-icon-delete ml-auto" (click)="closeModal()"></span>
    </div>
    <!-- modal header end -->

    <!-- modal body start -->
    <div class="modal__body">
      <!-- product summary start -->
      <div class="row product-summary">
        <!-- promo found // component??? -->
        <div
          class="col-12"
          *ngIf="pageUrlKey === 'use-my-coupon' && isFromSearch"
        >
          <div class="promo-found">
            <h3 class="mb-0 text-success">Promo Found</h3>
          </div>
        </div>

        <div class="col-md-5">
          <div class="swiper-container product-slider" [swiper]="productSlider">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                *ngFor="let productImage of productDetails?.media"
              >
                <img
                  class="img-fluid w-100 rounded"
                  [src]="productImage | image: 'src':'desktop'"
                  [alt]="productImage | image: 'alt'"
                />
              </div>
            </div>
            <div
              class="product-slider__button slider-prev"
              [hidden]="productDetails?.media?.length <= 1"
            >
              <span class="custom-icon-arrow-left"></span>
            </div>
            <div
              class="product-slider__button slider-next"
              [hidden]="productDetails?.media?.length <= 1"
            >
              <span class="custom-icon-arrow-right"></span>
            </div>
          </div>
        </div>

        <div class="col-md-7">
          <!-- product title visible only on desktop -->
          <h2 class="product-title d-none d-md-block">
            {{ productDetails?.title }}
          </h2>
          <!-- product description: visible only on desktop -->
          <div class="d-none d-md-block" *ngIf="productDetails?.description">
            <div
              class="product-description"
              [innerHTML]="productDetails?.description"
            ></div>
          </div>
          <!-- add '.promo-price' if promo use checked -->
          <!-- <p
            class="product__price"
            [class.promo-price]="useVoucherCode && productDetails?.voucher_code"
          >
            {{ productDetails?.apc_card_buy1take1_available ? getSelectedVariant('Size & Crust')?.price?.formatted : productDetails?.price?.formatted }}
          </p> -->
          <p
            class="product__price text-center text-md-left"
            [class.promo-price]="useVoucherCode && productDetails?.voucher_code"
          >
            {{
              defaultVariantType
                ? getSelectedVariant(defaultVariantType)?.price?.formatted
                : productDetails?.price?.formatted
            }}
          </p>
          <!-- <p
            class="product__price"
            [class.promo-price]="useVoucherCode && productDetails?.voucher_code"
          >
            {{ getSelectedVariant('Size & Crust')?.price?.formatted }}
          </p> -->
          <!-- product title visible only on mobile -->
          <!-- <div
            class="product__details d-md-none"
            [innerHTML]="productDetails?.description"
          ></div> -->
          <!-- action buttons visible only desktop -->
          <div class="d-block">
            <!-- <textarea
            *ngIf="productDetails"
            [(ngModel)]="productDetails.extra.dietary_requirements"
            class="form-control mb-3"
            placeholder="Do you have specific needs? Enter them here..."
          ></textarea> -->
            <div class="w-promo addcart d-flex align-items-center justify-content-center">
              <app-input-qty
                [product]="productDetails"
                [enableMaxQtyLimiter]="true"
                class="mr-md-4"
                *ngIf="!defaultVariantType"
              >
              </app-input-qty>
              <button
                *ngIf="!state"
                [disabled]="isAddToCartLoading || isTake1Loading"
                class="btn btn-primary btn-cart w-100 w-md-auto mt-md-0 mt-2 justify-content-center"
                (click)="
                  !isCartEdit
                    ? addToCart(productDetails)
                    : updateItemInCart(productDetails, productIndexFromCart)
                "
              >
                <span class="custom-icon-cart"></span>
                {{ !isCartEdit ? "Add to cart" : "Update" }}
              </button>
              <button
                *ngIf="!isCartEdit && user && state"
                [disabled]="isAddToCartLoading || isTake1Loading"
                class="btn btn-primary btn-favorite ml-md-2 w-md-auto w-100 mt-md-0 mt-2 justify-content-center"
                (click)="addToFavorite(productDetails)"
              >
                <span class="custom-icon-heart"></span>
                Add to my Favorite Set
              </button>
              <button
                *ngIf="isCartEdit"
                class="btn btn-dark btn-cart ml-md-2 w-md-auto w-100 mt-md-0 mt-2 text-center justify-content-center justify-content-md-start"
                (click)="removeItem(productDetails, productIndexFromCart)"
              >
                <span class="custom-icon-delete"></span>
                Remove
              </button>

              <!-- promo code for desktop -->
              <div
                class="block-promo form-group mb-0 ml-3 pl-3"
                *ngIf="
                  pageUrlKey === 'use-my-coupon' && productDetails.voucher_code
                "
              >
                <label for="" class="mb-0">Promo Code</label>

                <div class="d-flex align-items-center">
                  <!-- <input class="form-control" type="text" name="promo-code" [(ngModel)]="productDetails.voucher_code"> -->
                  <p class="font-weight-bold">
                    {{ productDetails.voucher_code }}
                  </p>

                  <!-- <div class="custom__checkbox ml-2">
                    <input [(ngModel)]="useVoucherCode" type="checkbox" name="use-promo" id="use-promo">
                    <label for="use-promo">
                      <span>Use</span>
                    </label>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!-- product content start -->
          <div class="product__content">
            <!-- product style -->
            <!-- <div class="product__content-item" *ngIf="productDetails?.style?.length">
          <label class="attributes__title">Style</label>
          <div *ngFor="let style of productDetails?.style; let i = index">
            <div class="custom__checkbox">
              <input type="checkbox" [name]="'style'+i" [id]="'style'+i" (change)="setAdditionalProduct(style)">
              <label [for]="'style'+i">
                <span class="checkbox__label">{{style?.title}}</span>
              </label>
            </div>
          </div>
        </div> -->

            <!-- <div class="col-7"> -->
            <!-- product flavors -->

          <hr *ngIf="productDetails?.variants && productDetails?.variants?.length > 0" />
            <div
              class="product__content-item"
              *ngFor="let variant of productDetails?.variants | keyvalue"
            >
              <div class="attributes__title">
                <label
                  >{{ variant.key.toLowerCase() | unslugify }}
                  <span
                    *ngIf="productDetails?.extra?.maximum_variant_selection"
                  >
                    {{
                      (productDetails?.extra?.maximum_variant_selection[
                        variant.key.toLowerCase().replaceAll(" ", "-")
                      ]?.maximum_selection | parseInt) > 0
                        ? "(Choose " +
                          productDetails?.extra?.maximum_variant_selection[
                            variant.key.toLowerCase().replaceAll(" ", "-")
                          ]?.maximum_selection +
                          ")"
                        : ""
                    }}
                  </span>
                </label>
                <!-- <span class="mx-2 d-none d-md-inline">|</span>
            <p class="attributes__subtitle">
              <span class="custom-icon-chili mr-2 text-primary"></span> - hotness level
            </p> -->
              </div>
              <div
                class="attributes__lists mb-2"
                *ngFor="let variantValue of variant.value; let index1 = index"
              >
                <p
                  *ngIf="variantValue?.variants?.length"
                  class="attributes__lists-title"
                >
                  {{ variantValue?.title }}
                </p>
                <div *ngIf="!variantValue?.variants?.length">
                  <ng-container
                    *ngIf="
                      (productDetails?.extra?.maximum_variant_selection[
                        variant.key.toLowerCase().replaceAll(' ', '-')
                      ]?.maximum_selection | parseInt) > 1
                    "
                  >
                    <div class="custom__checkbox">
                      <input
                        [disabled]="variantValue?.disabled"
                        [checked]="variantValue?.selected"
                        type="checkbox"
                        [name]="variantValue?.type"
                        (change)="setVariant(variantValue)"
                        [id]="variantValue?.type + '-' + index1"
                      />
                      <label [for]="variantValue?.type + '-' + index1">
                        <!-- <span>{{variantValue?.title}}</span> -->
                        <div style="display: inline-flex">
                          <span class="checkbox__label">{{
                            variantValue?.title
                          }}</span>
                          <app-input-qty
                            [product]="variantValue"
                            [enableMaxQtyLimiter]="true"
                            [small]="true"
                            (inputChanged)="inputChanged($event)"
                            *ngIf="variantValue?.selected"
                          >
                          </app-input-qty>
                        </div>
                        <p class="checkbox__description">
                          {{ variantValue?.description }}
                        </p>
                        <p
                          class="checkout__price"
                          *ngIf="variantValue?.price?.amount != '0'"
                        >
                          {{ variantValue?.price?.formatted }}
                        </p>
                      </label>
                    </div>
                  </ng-container>
                  <!-- Order Cart -->
                  <ng-container *ngIf="!isCartEdit">
                    <ng-container
                      *ngIf="
                        !productDetails?.extra?.maximum_variant_selection[
                          variant.key.toLowerCase().replaceAll(' ', '-')
                        ]?.maximum_selection ||
                        (productDetails?.extra?.maximum_variant_selection[
                          variant.key.toLowerCase().replaceAll(' ', '-')
                        ]?.maximum_selection | parseInt) === 1
                      "
                    >
                      <input
                        [checked]="variantValue?.selected"
                        type="radio"
                        [name]="variantValue?.type"
                        (change)="setVariant(variantValue, true); getTake1Items(((userService?.apcCardNumber || cartService?.hasApcCategory()) && productDetails?.apc_card_buy1take1_available || (!(userService?.apcCardNumber || cartService?.hasApcCategory()) && productDetails?.apc_card_double_deal)), true);"
                        [id]="variantValue?.type + '-' + index1"
                      />
                      
                      <label
                        [for]="variantValue?.type + '-' + index1"
                        class="radio-label"
                      >
                        <summary>
                          <div style="display: inline-flex" class="align-items-center">
                            <p
                              style="
                                align-items: center;
                                line-height: 30px;
                                margin: 0;
                                margin-right: 20px;
                              "
                            >
                              {{ variantValue?.title }}
                            </p>
                            <p *ngIf="variantValue?.price?.amount != '0'" style="
                            align-items: center;
                            line-height: 30px;
                            margin: 0;
                            margin-right: 20px;
                          ">
                              {{ variantValue?.price?.formatted }}
                            </p>
                            <app-input-qty
                              [product]="variantValue"
                              [enableMaxQtyLimiter]="true"
                              [small]="true"
                              (inputChanged)="inputChanged($event)"
                              *ngIf="variantValue?.selected && defaultVariantType === variantValue.type"
                            >
                            </app-input-qty>
                          </div>
                        </summary>
                      </label>
                    </ng-container>
                  </ng-container>
                  <!-- Edit Cart -->
                  <ng-container *ngIf="isCartEdit">
                    <ng-container
                      *ngIf="
                        !productDetails?.extra?.maximum_variant_selection[
                          variant.key.toLowerCase().replaceAll(' ', '-')
                        ]?.maximum_selection ||
                        (productDetails?.extra?.maximum_variant_selection[
                          variant.key.toLowerCase().replaceAll(' ', '-')
                        ]?.maximum_selection | parseInt) === 1
                      "
                    >
                      <input
                        [checked]="variantValue?.selected"
                        type="radio"
                        [name]="variantValue?.type"
                        (change)="setVariant(variantValue, true); 
                        getTake1Items(((userService?.apcCardNumber || 
                        cartService?.hasApcCategory()) && productDetails?.apc_card_buy1take1_available || 
                        (!(userService?.apcCardNumber || cartService?.hasApcCategory()) && productDetails?.apc_card_double_deal)), true);"
                        [id]="variantValue?.type + '-' + index1"
                      />
                      
                      <label
                        [for]="variantValue?.type + '-' + index1"
                        class="radio-label"
                      >
                        <summary>
                          <div style="display: inline-flex" class="align-items-center">
                            <p
                              style="
                                align-items: center;
                                line-height: 30px;
                                margin: 0;
                                margin-right: 20px;
                              "
                            >
                              {{ variantValue?.title }}
                            </p>
                            <p *ngIf="variantValue?.price?.amount != '0'" style="
                            align-items: center;
                            line-height: 30px;
                            margin: 0;
                            margin-right: 20px;
                          ">
                              {{ variantValue?.price?.formatted }}
                            </p>
                            <app-input-qty
                              [product]="variantValue"
                              [enableMaxQtyLimiter]="true"
                              [small]="true"
                              (inputChanged)="inputChanged($event)"
                              *ngIf="variantValue?.selected && defaultVariantType === variantValue.type"
                            >
                            </app-input-qty>
                          </div>
                        </summary>
                      </label>
                    </ng-container>
                  </ng-container>
                </div>

                <div
                  *ngFor="
                    let subVariant of variantValue?.variants;
                    let index2 = index
                  "
                >
                  <ng-container
                    *ngIf="
                      (productDetails?.extra?.maximum_variant_selection[
                        variant.key.toLowerCase().replaceAll(' ', '-')
                      ]?.maximum_selection | parseInt) > 1
                    "
                  >
                    <div class="custom__checkbox">
                      <input
                        type="checkbox"
                        [disabled]="subVariant?.disabled"
                        [checked]="subVariant?.selected"
                        [name]="subVariant?.type + '-' + index1 + index2"
                        (change)="setVariant(subVariant)"
                        [id]="subVariant?.type + '-' + index1 + index2"
                      />
                      <label [for]="subVariant?.type + '-' + index1 + index2">
                        <!-- <img *ngIf="subVariant?.media?.length" [src]="subVariant?.media | image: 'src': 'desktop'" [alt]="subVariant?.media | image: 'alt'" [title]="subVariant?.media | image: 'title'" class="img-fluid mr-2"> -->
                        <div style="display: inline-flex">
                          <span class="checkbox__label">{{
                            subVariant?.title
                          }}</span>
                        </div>
                        <div
                          style="margin-right: 20px"
                          *ngIf="subVariant?.extra?.level"
                        >
                          <app-chili-count
                            [chiliCount]="subVariant?.extra?.level"
                          ></app-chili-count>
                        </div>
                        <!-- <app-input-qty
                      [product]="subVariant"
                      [enableMaxQtyLimiter]="true"
                      [small]="true"
                      (inputChanged)="inputChanged($event)"
                      *ngIf="subVariant?.selected"
                    >
                    </app-input-qty>
                    <p class="checkbox__description">
                      {{ subVariant?.description }}
                    </p>
                    <!-- ADD FLAVOR'S PRICE -->
                        <p
                          class="checkout__price"
                          *ngIf="subVariant?.price?.amount != '0'"
                        >
                          {{ subVariant?.price?.formatted }}
                        </p>
                      </label>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      !productDetails?.extra?.maximum_variant_selection[
                        variant.key.toLowerCase().replaceAll(' ', '-')
                      ]?.maximum_selection ||
                      (productDetails?.extra?.maximum_variant_selection[
                        variant.key.toLowerCase().replaceAll(' ', '-')
                      ]?.maximum_selection | parseInt) === 1
                    "
                  >
                    <input
                      [checked]="subVariant?.selected"
                      type="radio"
                      [name]="subVariant?.type"
                      (change)="setVariant(subVariant, true)"
                      [id]="subVariant?.type + '-' + index1 + '-' + index2"
                    />
                    <label
                      [for]="subVariant?.type + '-' + index1 + '-' + index2"
                      class="radio-label mb-1"
                    >
                      <!-- <img *ngIf="subVariant?.media?.length" [src]="subVariant?.media | image: 'src': 'desktop'" [alt]="subVariant?.media | image: 'alt'" [title]="subVariant?.media | image: 'title'" class="img-fluid mr-2"> -->
                      <summary>
                        <div class="d-flex align-items-center">
                          <div style="display: inline-flex">
                            <p
                              style="
                                align-items: center;
                                line-height: 30px;
                                margin: 0;
                                margin-right: 20px;
                              "
                            >
                              {{ subVariant?.title }}
                            </p>
                          </div>
                          <div
                            style="margin-right: 20px"
                            *ngIf="subVariant?.extra?.level"
                          >
                            <app-chili-count
                              [chiliCount]="subVariant?.extra?.level"
                            ></app-chili-count>
                          </div>
                          <app-input-qty
                            [product]="subVariant"
                            [enableMaxQtyLimiter]="true"
                            [small]="true"
                            (inputChanged)="inputChanged($event)"
                            *ngIf="subVariant?.selected"
                          >
                          </app-input-qty>
                        </div>
                        <p class="radio__description">
                          {{ subVariant?.description }}
                        </p>
                        <p *ngIf="subVariant?.price?.amount != '0'">
                          {{ subVariant?.price?.formatted }}
                        </p>
                      </summary>
                    </label>
                  </ng-container>
                </div>
                <div></div>
              </div>
            </div>
            <!-- product flavor options -->
            <!-- <div class="product__content-item" *ngIf="productDetails?.flavor_options?.length">
          <label class="attributes__title">Flavor Options</label>
          <div *ngFor="let option of productDetails?.flavor_options; let i = index">
            <input type="radio" [name]="'opt'+1" [id]="'opt'+i" (change)="setVariant(option)">
            <label [for]="'opt'+i">{{option?.title}}</label>
          </div>
        </div> -->

            <!-- product add ons -->
            <div
              class="product__content-item"
              *ngFor="
                let additionalProduct of productDetails?.additionalProducts
                  | keyvalue
              "
            >
              <div class="attributes__title">
                <label>{{ additionalProduct.key | unslugify }}</label>
              </div>
              <div class="attributes__lists">
                <div
                  *ngFor="
                    let addon of additionalProduct.value
                      | sortPipe: 'order_column';
                    let i = index
                  "
                >
                  <div
                    class="custom__checkbox"
                    [class.w-thumbnail]="addon?.media?.length"
                    [class.w-price]="addon?.price?.formatted"
                  >
                    <input
                      [checked]="addon?.selected"
                      (change)="setAdditionalProduct(addon)"
                      type="checkbox"
                      [name]="addon?.type + '-' + i"
                      [id]="addon?.type + '-' + i"
                    />
                    <!-- <label [for]="addon?.type+'-'+i">
                  <span class="checkbox__label">{{addon?.title}}</span>
                  <p class="checkbox__description">{{addon?.price?.formatted}}</p>
                </label> -->
                    <label
                      [for]="addon?.type + '-' + i"
                      class="checkbox__label"
                    >
                      <img
                        *ngIf="addon?.media?.length"
                        [src]="addon?.media | image: 'src':'desktop'"
                        [alt]="addon?.media | image: 'alt'"
                        [title]="addon?.media | image: 'title'"
                        class="img-fluid mr-2"
                      />
                      <summary>
                        <p>{{ addon?.title }}</p>
                        <p>{{ addon?.price?.formatted }}</p>
                      </summary>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- product sides -->
            <!-- <p class="attributes__lists-title">Regular Side and Srink</p> -->
            <!-- <div class="ml-2" *ngIf="productDetails?.sides?.length">
          <b class="mb-2 d-block"> Side </b>
          <div  *ngFor="let side of productDetails?.sides; let i = index">
            <input type="radio" [name]="'sides'+i" [id]="'sides'+i" (change)="setAdditionalProduct(side)">
            <label for="regular-side" class="radio-label"> -->
            <!-- <img src="../../../assets/imgs/add-ons/addons_tomato.jpg" alt="" class="img-fluid"> -->
            <!-- <summary>
                <p>{{side?.title}}</p>
                <p>{{side?.price?.formatted}}</p>
              </summary>
            </label>
          </div>
        </div> -->
            <!-- </div> -->
            <!-- product content end -->
          </div>
        </div>
      </div>
      <!-- product summary end -->
      <!-- modal body end -->

      <!-- modal footer start: visible only on mobile -->
      <div class="modal__footer d-md-none">
        <div class="block-comments">
          <label for="">Comments</label>
          <textarea
            [(ngModel)]="productDetails.extra.dietary_requirements"
            class="form-control"
            rows="3"
            style="resize: none"
            placeholder="Do you have specific needs? Enter them here..."
          ></textarea>
        </div>

        <!-- promo code for mobile -->
        <div class="block-promo" *ngIf="productDetails.voucher_code">
          <label for="">Promo Code</label>

          <div class="d-flex align-items-center">
            <!-- <input class="form-control" type="text" name="promo-code" [(ngModel)]="productDetails.voucher_code" placeholder="Enter Promo Code..."> -->
            <p class="font-weight-bold mb-0">
              {{ productDetails.voucher_code }}
            </p>
            <!-- <div class="custom__checkbox ml-2 mb-0">
          <input [(ngModel)]="useVoucherCode" type="checkbox" name="use-promo" id="use-promo">
          <label for="use-promo" class="flex-nowrap">
            <span>Use</span>
          </label>
        </div> -->
          </div>
        </div>

        <div class="block-cart">
          <app-input-qty
            [product]="productDetails"
            [enableMaxQtyLimiter]="true"
          >
          </app-input-qty>
          <button
            [disabled]="isAddToCartLoading || isTake1Loading"
            class="btn btn-primary ml-2 ml-md-4 d-flex align-items-center"
            (click)="
              !isCartEdit
                ? addToCart(productDetails)
                : updateItemInCart(productDetails, productIndexFromCart)
            "
          >
            <span class="custom-icon-cart mr-1"></span>
            {{ !isCartEdit ? "Add to cart" : "Update" }}
          </button>
          <button
            *ngIf="isCartEdit"
            class="btn btn-dark btn-cart ml-md-2 ml-2 d-flex align-items-center"
            (click)="removeItem(productDetails, productIndexFromCart)"
          >
            <span class="custom-icon-delete mr-1"></span>
            Remove
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center my-4" *ngIf="isTake1Loading">
      <div class="spinner-border text-light" role="status" >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      id="take1-container"
      *ngIf="
      ((userService?.apcCardNumber || cartService?.hasApcCategory()) && productDetails?.apc_card_buy1take1_available || (!(userService?.apcCardNumber || cartService?.hasApcCategory()) && productDetails?.apc_card_double_deal)) && !isTake1Loading && take1Items?.length
      "
      class="mt-4"
    >

    <p class="title text-center" style="font-size: 26px;">
      Get your 2<sup>nd</sup> pizza for free!
    </p>
    <div class="row">
      <div class="col-12">
      <div class="row col-md-12">
        <div class="col-md-6">
          <p class="title text-primary">
            {{
              productDetails?.apc_card_buy1take1_available &&
              (cartService?.hasApcCategory() || userService?.apcCardNumber) &&
              !cartService?.isDoubleDealCategory(productDetails)
                ? "Select your "
                : "Choice of "
            }}2<sup>nd</sup> Pizza
          </p>
          <div class="selected shadow" (click)="toggleTake1Selections('take1')">
            <p>{{ take1ItemSelected?.title || "Select" }}</p>
            <span class="custom-icon-arrow-down"></span>
          </div>
          <div *ngIf="!take1SelectionsCollapse?.take1" style="background: white; border-radius: 20px; padding: 10px;">
            <div
              class="item"
              *ngFor="let take1Item of take1Items; let i = index"
              (click)="selectTake1Item(take1Item)"
            >
              <input
                          [checked]="take1ItemSelected?.id === take1Item?.id"
                          type="radio"
                          [name]="take1Item?.type"
                          [id]="take1Item?.type + '-' + i"
                        />
                        <label
                          [for]="take1Item?.type + '-' + i"
                          class="radio-label"
                        >{{ take1Item?.title }}</label>
            </div>
          </div>
        </div>
  
        <div class="col-md-6" *ngFor="let variantType of getVariantTypes(take1ItemSelected?.variants, 'type', defaultVariantType)">
          <p class="title text-primary">
            Select your {{getDefaultVariantTake1(take1ItemSelected, variantType)?.variantType?.title}}
          </p>
        <div class="selected shadow" (click)="toggleTake1Selections(variantType)">
          <p>{{ isOnArray(take1ItemSelected?.selectedVariants, 'type', variantType)?.title || "Select" }}</p>
          <span class="custom-icon-arrow-down"></span>
        </div>
        
        <div *ngIf="!take1SelectionsCollapse[variantType]" style="background: white; border-radius: 20px; padding: 10px;">
          <div
            class="item"
            *ngFor="let variant of getVariants(take1ItemSelected?.variants, variantType); let i = index"
            (click)="selectVariant(take1ItemSelected, variant)"
          >
            <input
                        [checked]="isOnArray(take1ItemSelected?.selectedVariants, 'id', variant?.id)"
                        type="radio"
                        [name]="variant?.type + 'take1'"
                        [id]="variant?.type + '-' + i + 'take1'"
                      />
                      <label
                        [for]="variant?.type + '-' + i + 'take1'"
                        class="radio-label"
                      >{{ variant?.title }}</label>
          </div>
        </div>
        </div>
      </div>
      
      </div>
      </div>
    </div>
    <!-- modal footer end -->
    <!-- <img src="../../../assets/imgs/angels/item-bg.svg" width="100%" class="mt-4"> -->
  </div>
</div>
