import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ToastService } from "src/app/services/toast/toast.service";

@Component({
  selector: "app-input-qty",
  templateUrl: "./input-qty.component.html",
  styleUrls: ["./input-qty.component.scss"],
})
export class InputQtyComponent implements OnInit {
  inputDisabled = false;
  productItem: any;
  isAvailable: any;
  quantity: any
  @Input() enableMinQtyLimiter = false;
  @Input() enableMaxQtyLimiter = false;
  @Input() small = false;
  @Input()
  set isAvail(isAvail) {
    this.isAvailable = isAvail 
  }
  
  @Input()
  set product(product) {
    this.productItem = product;
  }
  @Output() inputChanged = new EventEmitter<any>();
  @Input()
  set disabled(value) {
    this.inputDisabled = value;
  }
  constructor(
    private toastService: ToastService,) {}

  ngOnInit() {
    console.log(this.productItem);    
    
   }

  onQtyChanged(product) {
    //console.log(product);
    product.quantity = product.quantity || 1 ;
    // if (product.quantity > 50) {
    //   // product.quantity = 1;
    //   this.toastService.showDangerToast(`${product.title} has a maximum quantity of 50`)
    // }
    
    this.inputChanged.emit(product);
  }
}
