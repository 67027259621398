import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollListenerService {
  scrollListenerSubject = new Subject<any>();
  constructor() { }
}
