import { WindowResizeService } from './services/window-resize.service';
import { AppService } from './services/app/app.service';
import { BranchLocationService } from './services/branch-location/branch-location.service';
import { CartService } from './services/cart/cart.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { LoaderService } from './services/loader.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from './services/user/user.service';
import { StoreService } from './services/store/store.service';
import { ApiService } from './services/api/api.service';
import { ScrollListenerService } from './services/scroll-listener/scroll-listener.service';
import { Component, HostListener, Inject, PLATFORM_ID, Renderer2, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { SwUpdate } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import Pusher from 'pusher-js';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'angels-pizza-pwa';

  showAsyncLoader = false;
  showLoader = true;
  scrollTop = 0;
  userData = {
    token: null,
    user: null
  };
  // location
  locationState = false;
  // menu variables
  menuState = false;
  menus = [
    {
      title: 'Home',
      slug: '/',
      target: '_self'
    },
    {
      title: 'Menu',
      slug: '/menu/1',
      target: '_self',
    },
    // {
    //   title: 'Promo',
    //   slug: '/promo/1',
    //   target: '_self',
    // },
    {
      title: 'Stores',
      slug: '/locations',
      target: '_self',
    },
    {
      title: 'News',
      slug: '/news',
      target: '_self',
    },
    {
      title: 'APC Card',
      slug: '/apc-card',
      target: '_self',
    },
    // {
    //   title: 'Flavors',
    //   slug: '/flavors',
    //   target: '_self',
    // },
    {
      title: 'Our Story',
      slug: '/about-us',
      target: '_self'
    },
    {
      title: 'Contact Us',
      slug: '/contact-us',
      target: '_self',
    },
  ];

  // Cart variables
  isCartOpen = false;
  pusher: Pusher;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.scrollTop = window.scrollY;
    this.scrollListenerService.scrollListenerSubject.next(this.scrollTop);
  }
  constructor(
    config: NgbModalConfig,
    private cookie: CookieService,
    private modalService: NgbModal,
    public apiService: ApiService,
    private storeService: StoreService,
    private userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private scrollListenerService: ScrollListenerService,
    @Inject(PLATFORM_ID) private platfromId,
    private renderer: Renderer2,
    private cartService: CartService,
    private branchLocationService: BranchLocationService,
    public appService: AppService,
    public windowResizeService: WindowResizeService,
    private readonly injector: Injector,
    private swUpdate: SwUpdate, 
    private cdr: ChangeDetectorRef,
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    // this.router.events.subscribe((val) => {
    //   console.log(val instanceof NavigationEnd);
    //   console.log('val => ', val);
    //   const {url}:any = val
    //   if (val instanceof NavigationEnd && url) {
    //     let [path] = url.split('/').filter(x => !!x);
    //     console.log('path =>', path)
    //     let color = '#fff'
    //     // switch(path) {
    //     //   case "apc-card":
    //     //     document.body.style.background = 'white';
    //     //     break;
    //     //   default:
    //     //     document.body.style.background = 'transparent';
    //     // }
    //   }
    // })
  }

  ngOnInit() {
    this.appService.getAppSettings();
    this.cartService.getBuy1Take1Details();

    if (isPlatformBrowser(this.platfromId)) {
      this.swUpdateChecker();

      this.loadOnBrowser();
      this.onRefreshToken();
      // this.getProvinces();
      //this.realTimeLogout()

      this.getCategories();
      this.storageDataInit();
      this.injector.get(NgcCookieConsentService);
      this.branchLocationService.getStoresUrl();
    }
    

    this.route.queryParams.subscribe(params => {
      if (!params.irclickid) return;
      const nextMonth = new Date();
      nextMonth.setDate(nextMonth.getDate() + 30);
      this.cookie.set('irclickid', params.irclickid, nextMonth);
    });

    // this.checkCookieConsent();
  }


  async loadOnBrowser() {
    try {
      // get branches and assign a default branch in the service (removed)
      // await this.apiService.getBranches();

      await this.userService.getUser();
      await this.apiService.getSocialLinks();

      // const branch = await this.storeService.getItem('branch');
      // if (!branch) {
      //   this.storeService.setItem('branch', 'araneta');
      // }

      this.showLoader = false;
    } catch (err) {
      this.showLoader = false;
    }

    this.loaderService.loaderState
      .subscribe((state) => {
        this.showLoader = state.show;
        if (state.show) {
          this.renderer.removeClass(document.body, 'page-loaded');
        } else {
          this.renderer.addClass(document.body, 'page-loaded');
        }
      });

    this.loaderService.asyncLoaderState
      .subscribe(state => {
        this.showAsyncLoader = state.show;
      });

    this.userService.userSubject.subscribe(async user => {
      // this.branchLocationService.setUserBranchLocation();
      this.userData.user = user;
      this.userData.token = user ? await this.storeService.getItem('token') : null;
    });

    this.cartService.cartMenuMobileOpen.subscribe(state => {
      this.toggleCart(state);
    });

    this.routeLoaderEventInit();
    console.log('log');
    
  }

  routeLoaderEventInit() {
    if (isPlatformBrowser(this.platfromId)) {
      let prevUrl = this.router.url;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.log(event.url);
          this.onRefreshToken();
          if (prevUrl !== event.url && !event.url.includes('/menu')) {
            this.loaderService.show();
          }
          prevUrl = event.url;
          // (document.querySelector('.page-loader') as HTMLElement).classList.remove('hide');
        }
      });
    }
  }

  // async realTimeLogout() {
  //   try {

  //     //Pusher.logToConsole = true
  //     const token = await this.storeService.getItem("token"); 
  //     const user = await this.storeService.getItem('user');
  //     const pusher = new Pusher("AHbIAdWoVms5PeO4dRVO", {
  //       forceTLS: false,
  //       cluster: 'mt1',
  //       wsHost: 'staging.angelspizza.halcyondigitalhost.com',
  //       wsPort: 6001,
  //       disableStats: true,
  //       authEndpoint: 'https://api.staging.angelspizza.halcyondigitalhost.com/api/broadcasting/auth',
  //       auth: {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     })

  //     pusher.subscribe(`private-session-tracker.${user}`);
  //     pusher.bind('data', data => {
  //       console.log(data);
        
  //     })
  //     // const callback = (data) => {            
  //     //   if(data) {           
  //     //     this.userService.realTime()
  //     //   }
  //     // };
  //     // pusher.bind_global(callback);

  //   } catch (err) {
  //     console.log(err);      
  //   }
  // }

  async onRefreshToken() {
    const refreshToken = await this.storeService.getItem('refresh_token');
    const loginTimestamp = await this.storeService.getItem('login_timestamp');
    if (refreshToken && loginTimestamp) {
      const baseTimeMin = 30;
      const timeDiffMin = moment().diff(moment(loginTimestamp), 'minutes');
      if (baseTimeMin < timeDiffMin) {
        try {
          const res = await this.userService.refreshToken();
          switch (res) {
            case 'success':
              await this.storeService.setItem('login_timestamp', moment().format());
              // window.location.reload();
              break;
            case 'revoked':                            
              this.userService.revokeAuth();
              break;
          }
          return;
        } catch (err) {
          console.log(err);
        }

      }
    }
  }

  open(content, size?) {
    let options: any = {centered: true, ariaLabelledBy: 'modal-basic-title'}
    if (size) {
      options.size = size;
    }
    this.modalService.open(content, options);
  }

  // MENU 
  async getCategories() {
    try {
      await this.cartService.isBranchValid();
      const branch = await this.storeService.getItem('branch');
      const headers = {
        'x-branch': branch || 'all'
      };
      const apcCardCode = await this.storeService.getItem("apc");
      const res = await this.apiService.request({
        method: 'GET',
        url: `/product-categories?limit=0&orderBy=order_column${this.cartService.hasApcCategory() || apcCardCode ? '&except_apc_double_bundle=1': ''}`,
        headers,
        jsona: true
      });
      console.log('menu children => ', res);

      let categories = res;
      this.appendToMenu('Menu', [{id: 'e-gift-certificate', order_column: res.length ? res.length + 1 : 1, slug: '/e-gift-certificate/1', title: 'E-Gift Certificate', type: 'e-gift-certificate'}, ...res ]);

      // [temporary] if apc card is active do not show double deal
      // if (categories && categories.length > 0 && (this.cartService.hasApcCategory() || apcCardCode)) {
      //   categories = categories.filter(category => category.id !== 'double-deal')
      // }

      this.appendToMenu('Menu', [
        {
          id: 'e-gift-certificate', order_column: res.length ? res.length + 1 : 1, slug: '/e-gift-certificate/1', title: 'E-Gift Certificate', type: 'e-gift-certificate'
        }, 
        ...categories 
      ]);
    } catch (err) {
      console.log(err);
      this.apiService.showErrors(err);
    }
  }

  async getProvinces() {
    try {
      const branchProvinces = await this.apiService.request({
        method: 'GET',
        url: '/province-branches-for-location-page',
        jsona: true
      });
      const filteredBranchProvinces = branchProvinces.filter(branchProvince => branchProvince.branches.length);
      console.log(filteredBranchProvinces);
      this.appendToMenu('Stores', filteredBranchProvinces);
    } catch (err) {
      console.log(err);
    }
  }

  appendToMenu(title, arr) {
    this.menus.map(menu => {
      if (menu.title === title) {
        menu['children'] = [...arr]
      }
    });
  }

  toggleMenu(state) {
    this.menuState = state;
    if (state) {
      this.renderer.addClass(document.body, 'modal-open');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

  // checkCookieConsent() {
  //   if (document.cookie.indexOf('cookieconsent_status') > -1) {
  //     this.renderer.addClass(document.body, 'cookie-dismiss');
  //   }
  //   this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
  //     (event: NgcStatusChangeEvent) => {
  //       this.renderer.addClass(document.body, 'cookie-dismiss');
  //     });
  // }

  async storageDataInit() {
    this.userData.token = await this.storeService.getItem('token');
    this.userData.user = await this.storeService.getItem('user');
    this.branchLocationService.setUserBranchLocation();

    this.branchLocationService.setServiceBranch();

    await this.userService.getApcCardNumber();
  }

  async logout() {
    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'confirm-dialog-modal',
      centered: true
    });
    modalComponent.componentInstance.title = '';
    modalComponent.componentInstance.buttonText.accept = 'Yes';
    modalComponent.componentInstance.buttonText.cancel = 'No';
    modalComponent.componentInstance.confirmationMessage = 'Are you sure you want to logout?';
    modalComponent.result.then(async res => {
      if (res) {
        const logoutAction = true;
        this.userService.logout(logoutAction);
        this.router.navigate(['/']);
      } else {
        return;
      }
    });
  }

  // Cart Functions
  toggleCart(open?) { // if open has value, it will be always true <boolean>
    this.isCartOpen = open ? open : !this.isCartOpen;
    this.cartService.cartHeaderSubject.next(open ? open : this.isCartOpen);
    const token = this.storeService.getItem('token');
    if (!this.cartService.cartStorage && token) {
      this.cartService.getCartItems();
    }
  }

  closeCart(cartState) {
    this.isCartOpen = cartState;
  }

  async emptyCart() {
    if (!this.cartService.cartStorage) {
      return;
    }
    if (!this.cartService.cartStorage.length) {
      return;
    }
    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'confirm-dialog-modal',
      centered: true
    });
    modalComponent.componentInstance.title = 'Empty Cart';
    modalComponent.componentInstance.confirmationMessage = 'Are you sure you want to remove ALL items in your cart?';
    modalComponent.result.then(async res => {
      if (res) {
        if (this.userData.token) {
          try {
            this.loaderService.showAsyncLoader();
            await this.cartService.removeAllCartItems();            
            this.loaderService.hideAsyncLoader();
          } catch (err) {
            console.log(err);
            this.loaderService.hideAsyncLoader();
          }

        }
        this.cartService.emptyCart();
        if (this.router.url.includes('checkout') || this.router.url.includes('review-order')) {
          this.router.navigate(['menu/1']);
        }
        this.toggleCart();
      }
    });
  }

  swUpdateChecker() {
    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(() => {

          if (confirm('New version available. Load New Version?')) {
              window.location.reload();
          }

      });
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
 } 

}
