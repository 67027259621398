import { AppService } from './../../services/app/app.service';
import { BranchLocationService } from './../../services/branch-location/branch-location.service';
import { FormValidationService } from './../../services/validation/form-validation.service';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormdataService } from './../../services/formdata.service';
import { UserService } from './../../services/user/user.service';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart/cart.service';
import { ToastService } from './../../services/toast/toast.service';
import { StoreService } from './../../services/store/store.service';
import { GlobalService } from './../../services/global.service';
import { ApiService } from '../../services/api/api.service';
import { Component, OnInit, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, FacebookLoginProvider } from 'angularx-social-login';
import { LoaderService } from 'src/app/services/loader.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentialsForm: FormGroup;
  isShowPass = false;

  @Output() closeModal = new EventEmitter();
  @Output() openSignUpModal = new EventEmitter();
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private apiService: ApiService,
    private storageService: StoreService,
    private toastService: ToastService,
    private cartService: CartService,
    private router: Router,
    private userService: UserService,
    private formdataService: FormdataService,
    private loaderService: LoaderService,
    private title: Title,
    private branchLocationService: BranchLocationService,
    private appService: AppService,
    public modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.credentialsForm = this.formBuilder.group({
      username: ['', Validators.compose([
        FormValidationService.NoWhitespaceValidator,
        Validators.pattern(GlobalService.EMAIL_REGEX),
        Validators.maxLength(50),
        Validators.required,
      ])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      client_id: '3',
      client_secret: '7bQMIiFRjxwta6ZfUUWVvsdo1q9x1kni76oLVaxm'
    });
    let siteTitle = 'Login';
    if (this.appService.siteName) {
      siteTitle = `${siteTitle} - ${this.appService.siteName}`;
    }
    this.title.setTitle(siteTitle);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.loaderService.hide();
      }, 100);
    }
  }

  async login(isFbLogin?, accessToken?) {
    if (this.credentialsForm.valid || isFbLogin) {
      this.loaderService.showAsyncLoader();
      try {
        const headers = {
          Authorization: `Basic ${btoa(`${GlobalService.LOGIN_CLIENT_KEYS.ID_PASSWORD}:${GlobalService.LOGIN_CLIENT_KEYS.SECRET}`)}`
        };
        const reqBody = {
          ...this.credentialsForm.value,
          grant_type: isFbLogin ? 'social' : 'password',
          // client_id: GlobalService.LOGIN_CLIENT_KEYS.ID_PASSWORD,
          // client_secret: GlobalService.LOGIN_CLIENT_KEYS.SECRET,
          provider: isFbLogin ? 'facebook' : 'none',
        };
        if (isFbLogin) {
          reqBody.access_token = accessToken;
        }
        const loginRes: any = await this.apiService.postRequest({
          url: '/oauth/token',
          body: this.formdataService.generate(reqBody),
          headers
        });
        await this.storageService.setItem('token', loginRes.access_token);

        // user app wide update
        const isUpdate = true;
        const user = await this.userService.getUser(isUpdate, isFbLogin);        

        if (user) {
          const userKeys = Object.keys(user);
          if (userKeys.includes('has_verified_email')) {
            if (!user.has_verified_email) {
              this.loaderService.hideAsyncLoader();
              this.storageService.removeItem('token');
              this.toastService.showDangerToast('Failed to login. Please verify your email to continue.');
              return;
            }
          }
        }
        await this.branchLocationService.setUserBranchLocation(true); // specify `true` as login page execution


        await this.storageService.setItem('login_timestamp', moment().format());
        await this.storageService.setItem('refresh_token', loginRes.refresh_token);
        await this.storageService.setItem('social_login', isFbLogin ? true : false);
        const ignoreToast = true;
        this.cartService.emptyCart(ignoreToast);

        this.loaderService.hideAsyncLoader();

        if (loginRes.message) {
          this.toastService.showWarningToast(loginRes.message, 8000)
        } else {
          this.toastService.showSuccessToast('Successfully logged in.');
          this.closeModal.emit();
          this.router.navigate(['/']);
          //window.location.reload();
          const userProfile: any = await this.storageService.getItem('user')
          const token: any = await this.storageService.getItem('token')
          setTimeout(() => {            
            console.log(userProfile);
            if(userProfile.profile.address.fill_type === 'map') {
              const modalComponent = this.modalService.open(ConfirmDialogComponent, {
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'confirm-dialog-modal',
                centered: true,                           
              });
              let removeBtn = true              
              modalComponent.componentInstance.title = 'Caution!';
              modalComponent.componentInstance.removeBtn = removeBtn
              modalComponent.componentInstance.confirmationMessage = 
              "You are attempting to use a Map Address that is only available in the Angel's Pizza App, Please switch to a manual address.";
              modalComponent.result.then(async res => {
                if (res) {
                  if (token) {
                    try {
                      this.router.navigate(['/saved-addresses'])
                    } catch (err) {
                      console.log(err);                    
                    }          
                  }                 
                }
              });
            }
          }, 500);
        }

        // const branchLocation = await this.storageService.getItem('branchLocation');
        // if (branchLocation) {
        //   await this.storageService.removeItem('branchLocation');
        // }
        
      } catch (err) {
        if (err.status === 400) {
          this.loaderService.hideAsyncLoader();
          this.toastService.showDangerToast(`Login Failed. Wrong email or password.`);
          return;
        }
        if (err.status !== 401) {
          console.log(err);
          
          this.loaderService.hideAsyncLoader();
          const hasMessageKey = Object.keys(err.error).includes('message');
          if (hasMessageKey) {
            this.toastService.showDangerToast(err.error.message);
            return;
          }
          const errorFields = Object.keys(err.error);
          errorFields.filter(key => {
            err.error[key].filter(errMessage => {
              this.toastService.showDangerToast(errMessage);
            });
          });
          console.log(err);
        } else {
          this.loaderService.hideAsyncLoader();
        }
      }
    }
  }

  async loginWithFb() {
    try {
      const socialRes = await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
      console.log(socialRes);
      const isFbLogin = true;
      await this.login(isFbLogin, socialRes.authToken);
    } catch (err) {
      this.toastService.showDangerToast('Failed to login. Please try again.');
      console.log(err);
    }
  }

}
