import { Pipe, PipeTransform } from "@angular/core";

// TODO: "for temporary use only"

@Pipe({
  name: "sortPipe",
})
export class SortComponentPipe implements PipeTransform {
  transform(objArr, field): any[] {
    if (!objArr || objArr.length <= 0 || !field) return [];

    console.log("object array => ", objArr);

    objArr.sort((a:number, b:number) => a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0);

    // for (let i = 0; objArr.length > i; i++) {
    //   objArr[i][field];
    // }

    return objArr;

    // if(!objArr || objArr.length <= 0) return []

    // console.log('objArr => ', objArr);

    // if (field === 'delivery') {
    //   objArr = objArr
    //   .map(barangay => {

    //     if (!barangay || !barangay.branches || barangay.branches.length <= 0) return barangay;

    //     let branches = barangay.branches.sort(o => o.extra[field] && o.extra[field].status === value);

    //     console.log('barangay => ', barangay);
    //     console.log('branches =>', branches);

    //     return {
    //       ...barangay,
    //       branches
    //     }
    //   })
    //   .sort(barangay => barangay.branches && barangay.branches.length > 0);

    //   return objArr;
    // }

    // if (!objArr || !objArr.length) {
    //   return [];
    // }
    // return objArr.sort(o => o.extra[field] && o.extra[field].status === value);
  }
}
