import { HomeComponent } from './pages/home/home.component';
import { HomeModule } from './pages/home/home.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
  initialNavigation: 'enabled',
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'disabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    data: {
      seo: {
        title: `Home`,
        description: `&quot;Growing from strength to strength,
                      Buddy’s Restaurant will remain to be the pioneer on all Filipino fast food chain that satisfies our guests....&quot;`,
        image: null
      }
    }
  },
  {
    path: 'menu/:page',
    // component: MenuComponent,
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
    data: {
      seo: {
        title: `Menu`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
      }
    }
  },
  {
    path: 'menu/product/:item_segment',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
  },
  {
    path: 'menu/:page/:cat_segment',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
    data: {
      seo: {
        title: `Menu`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
      }
    }
  },
  {
    path: 'use-my-coupon/:page',
    // component: MenuComponent,
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
    data: {
      seo: {
        title: `Promo`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
      }
    }
  },
  {
    path: 'use-my-coupon/product/:item_segment',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
  },
  {
    path: 'use-my-coupon/:page/:cat_segment',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
    data: {
      seo: {
        title: `Use My Coupon`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
      }
    }
  },
  {
    path: 'use-my-coupon/product/select/:voucher_code',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
  },
  {
    path: 'e-gift-certificate/:page',
    // component: MenuComponent,
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
    data: {
      seo: {
        title: `E-Gift Certificate`,
        description: 'Checkout the menu for our tasty creations.',
        image: null
      }
    }
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  //   data: {
  //     seo: {
  //       title: `Login`,
  //       description: `Login`,
  //       image: null
  //     }
  //   }
  // },
  // {
  //   path: 'register',
  //   loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
  //   data: {
  //     seo: {
  //       title: `Register`,
  //       description: `Sign Up`,
  //       image: null
  //     }
  //   }
  // },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: {
      seo: {
        title: `Reset Password`,
        description: 'Reset your account password',
        image: null
      }
    }
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule),
    data: {
      seo: {
        title: `About Us`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    data: {
      seo: {
        title: `Contact Us`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'payments/:type',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
    data: {
      seo: {
        title: `Payments`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'account-dashboard',
    loadChildren: () => import('./pages/account-dashboard/account-dashboard.module').then(m => m.AccountDashboardModule),
    data: {
      seo: {
        title: `Account`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
    data: {
      seo: {
        title: `Account`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'favorite',
    loadChildren: () => import('./pages/favorite/favorite.module').then(m => m.FavoriteModule),
    data: {
      seo: {
        title: `Favorite`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'saved-addresses',
    loadChildren: () => import('./pages/saved-addresses/saved-addresses.module').then(m => m.SavedAddressesModule),
    data: {
      seo: {
        title: `Saved Addresses`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'under-maintenance',
    loadChildren: () => import('./pages/under-maintenance/under-maintenance.module').then(m => m.UnderMaintenanceModule),
    data: {
      seo: {
        title: `Under Maintenance`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailModule),
    data: {
      seo: {
        title: `Verify`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule),
    data: {
      seo: {
        title: `Checkout`,
        description: null,
        image: null
      }
    }
  },
  // { 
  //   path: 'locations/:location_item',
  //   loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule),
  //   data: {
  //     seo: {
  //       title: `Locations`,
  //       description: null,
  //       image: null
  //     }
  //   }
  // },
  { 
    path: 'locations',
    loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule),
    data: {
      seo: {
        title: `Locations`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'review-order',
    loadChildren: () => import('./pages/review-order/review-order.module').then(m => m.ReviewOrderModule),
    data: {
      seo: {
        title: `Review Order`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryModule),
    data: {
      seo: {
        title: `Order History`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
    data: {
      seo: {
        title: `News`,
        description: null,
        image: null
      }
    }
  },
  {
    path: 'apc-card',
    loadChildren: () => import('./pages/apc-card/apc-card.module').then(m => m.ApcCardModule),
    data: {
      seo: {
        title: `APC Card`,
        description: null,
        image: null
      }
    }
  },
  // { path: 'flavors', loadChildren: () => import('./pages/flavors/flavors.module').then(m => m.FlavorsModule) },
  // {
  //   path: 'verify',
  //   loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailModule),
  // }
  // { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  {
    path: 'privacy-policy', loadChildren: () => import ('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  { path: 'terms-condition', loadChildren: () => import('./pages/terms-condition/terms-conditions.module').then(m => m.TermsConditionsModule) },
  {
    path: '404', loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule),
    data: {
      seo: {
        title: `404`,
        description: null,
        image: null
      }
    }
  },
  {
    path: '**', redirectTo: '/404',
    data: {
      seo: {
        title: `404`,
        description: null,
        image: null
      }
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    HomeModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
