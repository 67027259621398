import { ToastService } from './../toast/toast.service';
import { StoreService } from './../store/store.service';
import { GlobalService } from '../global.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import Jsona from 'jsona';
import { isPlatformBrowser } from '@angular/common';
import { retry } from 'rxjs/operators';
import { Router } from '@angular/router';
const dataFormatter = new Jsona();
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  siteSettings: any;
  socialLinks: any;
  agreementsContent: any;
  defaultConfig = {
    headers: { Accept: 'application/json', 'Accept-Language': 'en' }
    // laravel-boilerplate
  };
  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private toastService: ToastService,
    private route: Router,
    @Inject(PLATFORM_ID) private platformId
  ) {}
  // pipe(retry(2)).
  async request(requestParams: RequestParams) {
    // const reqUrl = `${GlobalService.BASE_URL_API}${requestParams.url}`;
    const req = new HttpRequest(requestParams.method, `${GlobalService.BASE_URL_API}${requestParams.url}`);

    const headers = new HttpHeaders(
      {
        ...this.defaultConfig.headers,
        ...(requestParams.headers || {})
      }
    );

    const clonedReq = req.clone({
      method: requestParams.method,
      headers,
      body: this.clean(requestParams.body) || null,
      // responseType: 'text'
    });

    const res = await this.http.request(clonedReq).toPromise();
    return requestParams.jsona ? dataFormatter.deserialize(res['body']) : res['body'];
  }

  jsonaFormatter(body) {
    return dataFormatter.deserialize(body);
  }

  postRequest(requestParams) {
    const reqUrl = `${GlobalService.BASE_URL_API}${requestParams.url}`;
    const headers = new HttpHeaders(
      {
        ...this.defaultConfig.headers,
        ...(requestParams.headers || {})
      }
    );
    return this.http.post(reqUrl, this.clean(requestParams.body), { headers }).toPromise();
  }

  putRequest(requestParams) {
    const reqUrl = `${GlobalService.BASE_URL_API}${requestParams.url}`;
    const headers = new HttpHeaders(
      {
        ...this.defaultConfig.headers,
        ...(requestParams.headers || {})
      }
    );
    return this.http.patch(reqUrl, this.clean(requestParams.body), { headers }).toPromise();
  }

  addHeader(headerObject): HttpHeaders {
    return new HttpHeaders(headerObject);
  }

  async getSocialLinks() {
    try {
      const res = await this.request({
        url: '/value-store/social',
        method: 'GET'
      });
      this.socialLinks = res;
    } catch (err) {
      console.log(err);
    }
  }

  isMobile() {
    let check = false;
    ((a) => {
      // tslint:disable-next-line: max-line-length
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) { 
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window['opera']);
    return check;
  }

  async getBranches() {
    const branch = await this.storeService.getItem('branch');
    if (branch) {
      return;
    }
    try {
      const branchList = await this.request({
        method: 'GET',
        url: '/branches?limit=1',
        jsona: true
      });
      await this.storeService.setItem('branch', branchList[0].segment);
    } catch (err) {
      console.log(err);
    }
  }

  async getAgreements() {
    try {
      const aggreements = await this.request({
        method: 'GET',
        url: '/value-store/agreements'
      });
      this.agreementsContent = aggreements;
      console.log(this.agreementsContent);
    } catch (err) {
      console.log(err);
    }
  }


  async showErrors(err, delay?) {
    console.log(err)
    let isVoucherCodeValid = true;
    let isTake1Invalid = false;
    const errorFields = Object.keys(err.error);
    console.log('error =>', err);
    if (err.error && err.error.message && err.error.message == 'Take1 prohibited when no APC/double_deal.') {
      return {isTake1Invalid: true};
    }
    if (errorFields) {
      errorFields.filter(async key => {
        if (key.includes('voucher_codes')) {
          isVoucherCodeValid = false;
          let index = parseInt(key.replace('voucher_codes.', ''))
          let voucher_codes = JSON.parse(JSON.stringify(await this.storeService.getItem('voucher_codes'))) || [];
          voucher_codes.splice(index, 1)
          await this.storeService.setItem('voucher_codes', voucher_codes)
          err.error[key].filter(errMessage => {
            this.toastService.showDangerToast(errMessage, delay);
          });
        }
      });
    }

    if (err.error.errors) {
      const errFields = Object.keys(err.error.errors);
      errFields.filter(async key => {
        if (key === 'schedule_time') {
          this.route.navigate(['/checkout']);
        }
        if (key.includes('voucher_errors')) {
          isVoucherCodeValid = false;
          for (const k in err.error.errors[key]) {
            let voucher_codes = JSON.parse(JSON.stringify(await this.storeService.getItem('voucher_codes'))) || [];
            voucher_codes = voucher_codes.filter(c => key !== c)
            await this.storeService.setItem('voucher_codes', voucher_codes)
            this.toastService.showDangerToast(err.error.errors[key][k], delay);
          }
        } else if (key.includes('password')) {
          isVoucherCodeValid = false;
          for (const k in err.error.errors[key]) {
            let voucher_codes = JSON.parse(JSON.stringify(await this.storeService.getItem('voucher_codes'))) || [];
            voucher_codes = voucher_codes.filter(c => key !== c)
            await this.storeService.setItem('voucher_codes', voucher_codes)
            this.toastService.showDangerToast(err.error.errors[key][k], delay);
          }
        } else {
          for (const k in err.error.errors) {
            let voucher_codes = JSON.parse(JSON.stringify(await this.storeService.getItem('voucher_codes'))) || [];
            voucher_codes = voucher_codes.filter(c => key !== c)
            await this.storeService.setItem('voucher_codes', voucher_codes)
            this.toastService.showDangerToast(err.error.errors[k], delay);
          }
        }
        
      });
    } else {
      const hasMessageKey = Object.keys(err.error).includes('message');
      if (hasMessageKey) {
        this.toastService.showDangerToast(err.error.message, delay);
        return;
      }
    }

    
    const errorCode = Object.keys(err.error);
    errorCode.filter(key => {
      if (key.includes('code')) {
        // this.promoCodeErrMessage = err.error[key][0];
        this.toastService.showDangerToast(err.error[key][0]);
      }
    });
    
    return { isVoucherCodeValid };
  }


  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName]
      }
    }
    return obj;
  }

}

export interface RequestParams {
  url: string;
  method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS';
  body?: any;
  headers?: object;
  token?: string;
  jsona?: boolean;
}
