import { AppService } from './app/app.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RouteHelperService {
  routeSubject = new Subject<any>();
  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.setupRouting();
  }

  private setupRouting() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary')
    ).subscribe((route: ActivatedRoute) => {
      this.routeSubject.next(route);
      const seo = route.snapshot.data['seo'];
      // set your meta tags & title here
      if (seo) {
        const isPageMeta = true;
        this.appService.updateOgTags(seo, isPageMeta);
        this.scrollToTop();
      }
    });
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
}
