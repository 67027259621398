import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApcCardInputModalComponent } from "../components/apc-card-input-modal/apc-card-input-modal.component";
import { UserService } from "./user/user.service";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private modalService: NgbModal, private userService: UserService) {}

  openApcCardInputModal(withQuestions = false) {
    if (this.userService.apcCardNumber) {
      window.location.reload();
      return;
    }
    
    const modalRef = this.modalService.open(ApcCardInputModalComponent, { centered: true })
    modalRef.componentInstance.withQuestions = withQuestions;
    modalRef.componentInstance.isShowCardInput = !withQuestions
    modalRef.result.then((value) => console.log('value => ', value));
  }
}
