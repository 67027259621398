import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-item-comment',
  templateUrl: './item-comment.component.html',
  styleUrls: ['./item-comment.component.scss']
})
export class ItemCommentComponent implements OnInit {
  product: any;
  index: number;
  modalCtrl: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeModal(false);
  }
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  closeModal(saveChanges) {
    if (this.modalCtrl) {
      this.modalCtrl.close(saveChanges ? this.product : null);
      return;
    }
    this.modalService.dismissAll(null);
  }
}
