<div class="address-bg overflow-hidden border-radius-address height-100 width-100 pb-3">
  <div class="address">
    <!-- Modal header start -->
    <div class="modal__header">
      <div class="closeBtn" (click)="closeModal()">
        <span class="custom-icon-arrow-left"></span><label>Edit Address</label>
      </div>
    </div>
    <!--Modal header End-->
    <!--Modal Body Start-->
    <div class="modal__body">
      <div class="container">
        <div class="body_title pb-5">Recepients Details</div>
        <form [formGroup]="addressForm" class="inputstyle">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">First Name *</label>
                <div class="col-sm-12">
                  <input 
                    maxlength="50" 
                    class="form-control"
                    type="text" 
                    formControlName="recipient_first_name"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_first_name')"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_first_name") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Last Name *</label>
                <div class="col-sm-12">
                  <input 
                    maxlength="50" 
                    class="form-control" 
                    type="text" 
                    formControlName="recipient_last_name"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_last_name')"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_last_name") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-12 form-label">Email *</label>
            <div class="col-sm-12 col-md-12">
              <input 
                maxlength="50" 
                class="form-control" 
                type="email" 
                formControlName="recipient_email"
                [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_email')"
              >
              <div class="invalid-feedback">
                {{ fieldErrorMessage(addressForm, "recipient_email") }}
              </div>
            </div>
          </div> 
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Mobile No. </label>
                <div class="col-sm-12">
                  <input 
                    maxlength="13"
                    minlength="11" 
                    appContactNumber
                    class="form-control" 
                    type="tel" 
                    formControlName="recipient_mobile"
                    placeholder="09XXXXXXXXX"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_mobile')"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_mobile") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Landline No. </label>
                <div class="col-sm-12">
                  <input 
                    maxlength="10"
                    minlength="9" 
                    appContactNumber
                    class="form-control" 
                    type="tel" 
                    formControlName="recipient_landline"
                    [class.is-invalid]="fieldErrorMessage(addressForm, 'recipient_landline')"
                    placeholder="02XXXXXXXX"
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "recipient_landline") }}
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div class="form-group row">
            <label class="col-sm-12 form-label">Save Address Name as: *</label>
            <div class="col-sm-12">
              <input 
                maxlength="50" 
                class="form-control" 
                type="text" 
                placeholder="ex. Jenna's House" 
                formControlName="name"
                [class.is-invalid]="
                fieldErrorMessage(addressForm, 'name')"
              >
              <div class="invalid-feedback">
                {{ fieldErrorMessage(addressForm, "name") }}
              </div>
            </div>
          </div> 
          <div class="body_title py-5">Delivery Address</div>   
          <div class="form-group row">
            <label class="col-sm-12 form-label">Address 1 *</label>
            <div class="col-sm-12">
              <input 
                maxlength="150" 
                class="form-control" 
                type="text" 
                formControlName="delivery_address"
                [class.is-invalid]="
                fieldErrorMessage(addressForm, 'delivery_address')"
              >
              <div class="invalid-feedback">
                {{ fieldErrorMessage(addressForm, "delivery_address") }}
              </div>
            </div>
          </div>   
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Province *</label>
                <div class="col-sm-12">
                  <!-- <input maxlength="150" class="form-control" type="text" formControlName="delivery_state"> -->

                  <div ngbDropdown>
                    <button
                      [disabled]="!branchLocations?.provinces?.length"
                      class="btn form-dropdown"
                      ngbDropdownToggle
                    >
                      <label>{{
                        addressForm.get("delivery_state").value?.title ||
                          "Select Province"
                      }}</label>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        ngbDropdownItem
                        *ngFor="let province of this.branchLocations.provinces"
                        (click)="setBranch(province, 'state')"
                      >
                        {{ province?.title }}
                      </button>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">City *</label>
                <div class="col-sm-12">
                  <div ngbDropdown>
                    <button
                      [disabled]="!branchLocations?.city?.length || !addressForm.get('delivery_state')?.value"
                      class="btn form-dropdown"
                      ngbDropdownToggle
                    >
                      <label>{{
                        addressForm.get("delivery_city").value?.title ||
                          "Select City"
                      }}</label>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        ngbDropdownItem
                        *ngFor="let city of branchLocations.city"
                        (click)="setBranch(city, 'city')"
                      >
                        {{ city?.title }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Barangay *</label>
                <div class="col-sm-12">
                  <div ngbDropdown>
                    <button
                      [disabled]="!branchLocations?.barangay?.length || !addressForm.get('delivery_city')?.value"
                      class="btn form-dropdown"
                      ngbDropdownToggle
                    >
                      <label>{{
                        addressForm.get("delivery_barangay").value?.title || "Select Barangay"
                      }}</label>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        ngbDropdownItem
                        *ngFor="let barangay of branchLocations?.barangay"
                        (click)="setBranch(barangay, 'barangay')"
                      >
                        {{ barangay?.title }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group row">
                <label class="col-sm-12 form-label">Company</label>
                <div class="col-sm-12">
                  <input 
                    maxlength="50" 
                    class="form-control" 
                    type="text" 
                    formControlName="delivery_company"
                    [class.is-invalid]="
                    fieldErrorMessage(addressForm, 'delivery_company')"              
                  >
                  <div class="invalid-feedback">
                    {{ fieldErrorMessage(addressForm, "delivery_company") }}
                  </div>
                </div>
              </div>
            </div>
          </div>       
        </form>
      </div>
    </div>
    <!--Modal Body End-->
    <!--Modal Footer Start-->
    <div class="modal__footer d-flex justify-content-center">
      <button class="btn btn-success btn-address" (click)="updateAddress()">Save Changes</button>
      <button class="btn btn-danger btn-address" (click)="closeModal()">Cancel</button>
    </div>
    <!--Modal Footer End-->
  </div>
</div>
