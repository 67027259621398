import { ResponseInterceptorModule } from './response-interceptor.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbCollapseModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from './components/components.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';

// Universal
import { TransferHttpCacheModule } from '@nguniversal/common';

// Packages
import { NgForageModule, NgForageConfig, Driver } from 'ngforage';
import { AuthServiceConfig, SocialLoginModule, FacebookLoginProvider } from 'angularx-social-login';
// import { FacebookModule } from 'ngx-facebook';

// SERVICES
import { StoreService } from './services/store/store.service';
import { FormValidationService } from './services/validation/form-validation.service';
import { WindowResizeService } from './services/window-resize.service';
import { RouteHelperService } from './services/route-helper.service';
import { AppService } from './services/app/app.service';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { DecimalPipe } from '@angular/common';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('291429762047995')
  }
]);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'angelspizza.com.ph'
  },
  palette: {
    popup: { "background": '#fff' }, "button": { "background": '#f2cc1d'}
  },
  type: 'info',
  theme: "classic",
  position: 'bottom-left',
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
    </span>
    `,
  },
  content: {
    message: "We use cookies on this site to enchance your user experience. By clicking any link on this page you are giving your consent for us to set cookies",
    dismiss: "Accept & Close",

    // cookiePolicyLink: 'Cookie Policy',
    // cookiePolicyHref: 'https://cookie.com',

    privacyPolicyLink: 'Learn more',
    // privacyPolicyHref: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/privacy-policy`,
    privacyPolicyHref: `https://angelspizza.com.ph/privacy-policy`,
  }
};

export function socialConfig() {
  return config;
}
@NgModule({
  declarations: [
    AppComponent,    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    // NgForageModule.forRoot({
    //   name: 'buddys-store',
    //   driver: [ // Defaults to indexedDB > webSQL > localStorage
    //     Driver.INDEXED_DB,
    //     Driver.LOCAL_STORAGE
    //   ]
    // }),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    TransferHttpCacheModule,
    ResponseInterceptorModule,

    // BOOTSTRAP MODULES
    NgbToastModule,
    // FacebookModule.forRoot()

    NgcCookieConsentModule.forRoot(cookieConfig),
    LazyLoadImageModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AppService,
    StoreService,
    FormValidationService,
    RouteHelperService,
    WindowResizeService,
    CookieService,
    { provide: AuthServiceConfig, useFactory: socialConfig },
    DecimalPipe,
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(ngfConfig: NgForageConfig) {
    ngfConfig.configure({
      name: 'angels-pizza-store',
      driver: [ // Defaults to indexedDB > webSQL > localStorage
        Driver.INDEXED_DB,
        Driver.LOCAL_STORAGE
      ]
    });
  }
}
